import { useCustomCompareEffect } from "use-custom-compare";
import isEqual from "lodash/isEqual";
import UserService from "../../services/user.service";
import React, { useState, useEffect, useRef } from "react";
import moment from "moment-timezone";
import ReactPaginate from "react-paginate";
import IconButton from "@mui/material/IconButton";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ReactApexChart from "react-apexcharts";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import Switch from "@mui/material/Switch";
import { Typography } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Card, CardContent, Grid } from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";
import Boxes from "./Boxes2";
import PhaseBox from "./PhaseBoxes";
import PFBox from "./PowerFactorBox";
// import PFBox from "./PhaseBoxes";
import Access_profiles from "./profiles";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
// import DatePicker from "react-datepicker";
import DatePicker, { CalendarContainer } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Skeleton from "react-loading-skeleton";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import inflect from "i";
import Control from "../Control";
import BillingView from "./BillingView";
import Alarm from "./Alarms";
import SiteDetails from "./SiteDetails";
import NewEnergycomponent from "./NewEnergycomponent";
const tzone = "Asia/Amman";
let months_name = [
  "",
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",]
// Power_fields_2 = ["Solar Power","Load Power"][6,17]["Power_Total_IEL_A","Zero_Total_Power"]
// Power_Load = ["Total Load","L1","L2","L3"][17,14,15,16]["Zero_Total_Power","Power_SM_L1_A","Power_SM_L2_A","Power_SM_L3_A"]
// Power_Solar = ["IEL Solar","Solis Solar"][6,9]["Power_Total_IEL_A","Power_Solis_AC_T_A"]

// energy2 = ["Solar Energy","Load energy","Grid Energy"][13,13,18]["Accumulated_Energy_Solis","Accumulated_Energy_Solis","Energy_Grid_SM_T_A"]
// energy3 = ["IEL Energy","Solis energy",][7,13]["Accumulated_Energy_IEL","Accumulated_Energy_Solis"]

function calculateDifferences(inputArray) {
  // Create a new array to store the results
  let resultArray = [];
  if (inputArray.length == 1) {
    return inputArray;
  }
  console.log(inputArray);
  // resultArray.push(inputArray[0]);
  // Loop through the input array starting from the second element (index 1)
  for (let i = 1; i < inputArray.length; i++) {
    // Calculate the difference between T_Energy_Acc of current element and T_Energy_Acc of the previous element
    let diff = inputArray[i][1] - inputArray[i - 1][1];
    diff = diff % 1 !== 0 ? parseFloat(diff.toFixed(2)) : diff;

    // Create a new object with the 'diff' property and other properties from the current element
    let resultElement = [inputArray[i][0], diff >= 0 ? diff : 0];
    console.log(resultElement);
    // Add the new object to the result array
    resultArray.push(resultElement);
  }

  resultArray.unshift(inputArray[0][1] >= 0 ? inputArray[0] : 0);
  console.log(resultArray);
  // Return the result array
  return resultArray;
}

function fillMissingGaps(data, type) {
  let result = [];
  const template = {};
  const template2 = {};

  const startOfMonth = parseInt(
    moment(data[0][0]).startOf("month").format("DD")
  );
  const endOfMonth = parseInt(moment(data[0][0]).endOf("month").format("DD"));
  const Day = parseInt(moment(data[0][0]).format("DD"));
  const Month = parseInt(moment(data[0][0]).endOf("month").format("MM"));
  const Year = parseInt(moment(data[0][0]).endOf("month").format("YYYY"));

  if (type == "hourly") {
    data.forEach((x) => {
      template2[moment(x[0]).format("HH")] = x;
    });
    for (let num = 0; num <= 23; num++) {
      if (num < 10) {
        template["0" + num] = [
          moment(`${Year}-${Month}-${Day}`).format(`YYYY-MM-DD 0${num}:00:00`),
          null,
        ];
      } else {
        template["" + num] = [
          moment(`${Year}-${Month}-${Day}`).format(`YYYY-MM-DD ${num}:00:00`),
          null,
        ];
      }
    }
    // console.log("+++", template);
    //console.log(template2);

    result = Object.values({ ...template, ...template2 }).sort((a, b) =>
      a[0].localeCompare(b[0], "en", { numeric: true })
    );
    return result;
  } else if (type == "daily") {
    data.forEach((x) => {
      template2[moment(x[0]).format("DD")] = x;
    });

    for (let num = startOfMonth; num <= endOfMonth; num++) {
      if (num < 10) {
        template["0" + num] = [
          moment(`${Year}-${Month}-${num}`).format("YYYY-MM-DD"),
          null,
        ];
      } else {
        template["" + num] = [
          moment(`${Year}-${Month}-${num}`).format("YYYY-MM-DD"),
          null,
        ];
      }
    }

    result = Object.values({ ...template, ...template2 }).sort((a, b) =>
      a[0].localeCompare(b[0], "en", { numeric: true })
    );
    // console.log("***", result);
    // console.log("+++", template);
    // console.log("+++", template2);
    // console.log("*/*/", result);
    return result;
  } else if (type == "monthly") {
    data.forEach((x) => {
      template2[moment(x[0]).format("MM")] = x;
    });

    for (let num = 1; num <= 12; num++) {
      if (num < 10) {
        template["0" + num] = [
          moment(`${Year}-0${num}-02`).format("YYYY-MM-DD  00:00:00"),
          null,
        ];
      } else {
        template["" + num] = [
          moment(`${Year}-${num}-02`).format("YYYY-MM-DD  00:00:00"),
          null,
        ];
      }
    }
    console.log("+++", template2);
    result = Object.values({ ...template, ...template2 }).sort((a, b) =>
      a[0].localeCompare(b[0], "en", { numeric: true })
    );
    // console.log("***", result);
    // console.log("+++", template2);
    // console.log("*/*/", result);
    return result;
  }
}

let Power_fields = [
  "Power_SM_L1_A",
  "Power_SM_L2_A",
  "Power_SM_L3_A",
  "Zero_Total_Power",
  "Power_Total_IEL_A",
  "Power_Solis_DC_T_A",
  "Power_Solis_AC_T_A",
];

let Power_fields_2_old = ["Power_Total_IEL_A", "Zero_Total_Power"];
let Power_fields_2 = [
  "Power_Total_IEL_A",
  "Zero_Total_Power",
  "Power_Solis_AC_T_A",
];
let Power_Load = [
  "Zero_Total_Power",
  "Power_SM_L1_A",
  "Power_SM_L2_A",
  "Power_SM_L3_A",
];
let Power_Solar = ["Power_Total_IEL_A", "Power_Solis_AC_T_A"];

let energy2 = [
  // "Accumulated_Energy_Solis",
  "Accumulated_Energy_Solis",
  "Energy_Grid_SM_T_A",
];
let energy3 = ["Accumulated_Energy_IEL", "Accumulated_Energy_Solis"];

let Temperature_fields = ["Temp_IEL"];

let Energy_fields_old = [
  "Energy_Solis_Today",
  "Energy_Solis_Month",
  "Solar_Energy_Year",
  "Energy_Grid_SM_T_A",
  "Accumulated_Energy_IEL",
  "Accumulated_Energy_Solis",
];
let Energy_fields = [
  "Energy_Grid_SM_T_A",
  "Accumulated_Energy_IEL",
  "Accumulated_Energy_Solis",
];

export default function App(props) {
  const { device_id, StatementName, userID, SharedProfile, devicename, isDeviceStatus, treeID, parentID } = props;
  // console.log(device_id, StatementName, userID, SharedProfile);
  const chartRef = useRef();
  const [data, setData] = useState([]);
  const [data1, setData1] = useState([]);
  const [data2, setData2] = useState([]);
  const [data3, setData3] = useState([]);
  const [data4, setData4] = useState([]);
  const [data5, setData5] = useState([]);
  const [data6, setData6] = useState([]);
  const [Parameter, setParameter] = React.useState("power1");
  const [Rt, setRt] = React.useState(true);
  const [TimeFormat, setTtimeFormat] = React.useState("daily");
  const [LastDataFront, setLastDataFront] = useState(0);
  const [FirstDataBack, setFirstDataBack] = useState(0);
  const [processSelect, setProcessSelect] = React.useState("solis");
  const [EnergyYear, setEnergyYear] = React.useState("25");
  const [EnergyMonth, setEnergyMonth] = React.useState("1");
  const [EnergyDay, setEnergyDay] = React.useState("16");
  const [EnergyType, setEnergyType] = React.useState("1");
  const [profileAccess, setProfileAccess] = React.useState({
    Accumulated_Energy_Solis: 0,
    Accumulated_Energy_IEL: 0,
    Zero_Total_Power: 0,
    Power_Total_IEL_A: 0,
    Power_Load: 0,
    Power_load_menu: 0,
    Bill_menu: 0,
    Hourly_menu: 0,
    Bottom_boxes: 0,
    site_detail: 0,
    site_edit: 0,
  });
  const [phases, setPhases] = React.useState(3);
  const [phase11, setPhase11] = React.useState({
    voltage: 0,
    current: 0,
    power: 0,
  });
  const [phase12, setPhase12] = React.useState({
    voltage: 0,
    current: 0,
    power: 0,
  });
  const [phase13, setPhase13] = React.useState({
    voltage: 0,
    current: 0,
    power: 0,
  });

  const [phase21, setPhase21] = React.useState({
    voltage: 0,
    current: 0,
    power: 0,
  });
  const [phase22, setPhase22] = React.useState({
    voltage: 0,
    current: 0,
    power: 0,
  });
  const [phase23, setPhase23] = React.useState({
    voltage: 0,
    current: 0,
    power: 0,
  });

  const [phase31, setPhase31] = React.useState({
    voltage: 0,
    current: 0,
    power: 0,
  });
  const [phase32, setPhase32] = React.useState({
    voltage: 0,
    current: 0,
    power: 0,
  });
  const [phase33, setPhase33] = React.useState({
    voltage: 0,
    current: 0,
    power: 0,
  });

  const [placeHolder1, setPlaceHolder1] = useState([]);
  const [placeHolder2, setPlaceHolder2] = useState([]);
  const [placeHolder3, setPlaceHolder3] = useState([]);
  const [isLoadingGraph, setisLoadingGraph] = useState(false);
  const [DataAvailable, setDataAvailable] = useState(false);
  const [startDate, setstartDate] = useState("");
  const [endDate, setendDate] = useState("");
  const [staticValue1, setStaticValue1] = useState(0);
  const [staticValue2, setStaticValue2] = useState(0);
  const [staticValue3, setStaticValue3] = useState(0);
  const [staticValue4, setStaticValue4] = useState(0);
  const [staticValue5, setStaticValue5] = useState(0);
  const [staticValue6, setStaticValue6] = useState(0);
  const [staticValue7, setStaticValue7] = useState(0);

  const [staticValue11, setStaticValue11] = useState(0);
  const [staticValue12, setStaticValue12] = useState(0);
  const [staticValue13, setStaticValue13] = useState(0);
  const [staticValue14, setStaticValue14] = useState(0);
  const [staticValue15, setStaticValue15] = useState(0);
  const [staticValue16, setStaticValue16] = useState(0);

  const [chartConfig, setChartConfig] = useState({
    chart: {
      id: "total-power-chart",
      // type: "line",
      // stacked: true,
      height: 500,
      foreColor: "#000000",
      zoom: {
        type: "x",
        enabled: true,
        autoScaleYaxis: true,
      },

      toolbar: {
        autoSelected: "zoom",
      },
    },
    dataLabels: {
      enabled: false,
    },
    markers: {
      size: 0,
    },
    xaxis: {
      type: "datetime",
      tooltip: {
        enabled: false,
      },
      labels: {
        rotate: -90,
        rotateAlways: true,
        minHeight: 100,
        maxHeight: 160,
        show: true,
        align: "left",
        trim: true,
        // minWidth: 0,
        // maxWidth: 1200,
        style: {
          colors: ["#000000"],
          fontSize: "15px",
          // fontFamily: 'Helvetica, Arial, sans-serif',
          fontWeight: 600,
        },
      },
    },
    stroke: {
      width: 2,
    },
    colors: ["#0054F1", "#0054F1", "#0054F1", "#0054F1"],
    yaxis: {
      opposite: true,
      // formatter: (value) => {
      //   return value ;
      // },
      min: 0,
      decimalsInFloat: 2,
      forceNiceScale: true,
      axisBorder: {
        show: true,
        color: "#78909C",
        offsetX: 0,
        offsetY: 0,
      },
      axisTicks: {
        show: true,
        borderType: "solid",
        color: "#78909C",
        width: 6,
        offsetX: 0,
        offsetY: 0,
      },
      labels: {
        show: true,
        align: "right",
        minWidth: 0,
        maxWidth: 160,
        style: {
          colors: [],
          fontSize: "15px",
          // fontFamily: 'Helvetica, Arial, sans-serif',
          fontWeight: 600,
        },
        offsetX: 0,
        offsetY: 0,
        rotate: 0,
        // formatter: (value) => { return val },
      },
      crosshairs: {
        show: true,
        position: "back",
        stroke: {
          color: "#b6b6b6",
          width: 0.6,
          dashArray: [0, 1, 2],
        },
      },
      tooltip: {
        enabled: false,
        offsetX: 0,
      },
      title: {
        text: "[KW2]",
        rotate: 0,
        offsetX: 40,
        offsetY: -170,
        style: {
          color: undefined,
          fontSize: "15px",
          // fontFamily: 'Helvetica, Arial, sans-serif',
          fontWeight: 600,
          // cssClass: 'apexcharts-yaxis-title',
        },
      },
    },
    legend: {
      showForSingleSeries: true,
      offsetX: 7,
      //  offsetY:5,
      position: "top",
      fontSize: "18px",
      //  width:1
      //  fontWeight: 800,
      markers: {
        width: 12,
        height: 12,
        strokeWidth: 2,
        strokeColor: "#fff",
        fillColors: undefined,
        radius: 12,
        offsetX: -5,
        // offsetY: 5
      },
    },
  });

  const setPanelSiteDetail = (type) => {
    console.log(profileAccess);
    if (type === "site") if (profileAccess.site_detail) setParameter("site");
    if (type === "alarm") setParameter("alarm");
  };

  const setAlarm = (level) => {
    setStaticValue7(level);
  };


  const handleChangeProcessSelect = (event, newAlignment) => {
    console.log(newAlignment);
    setProcessSelect(newAlignment);
  };

  const handleChangeEnergyYear = (event, newAlignment) => {
    console.log("EnergyYear", event.target.value);
    setEnergyYear(event.target.value);
  };

  const handleChangeEnergyMonth = (event, newAlignment) => {
    console.log("EnergyMonth", EnergyMonth);
    setEnergyMonth(event.target.value);
  };
  const handleChangeEnergyDay = (event, newAlignment) => {
    console.log("EnergyDay", EnergyDay);
    setEnergyDay(event.target.value);
  };
  const handleChangeEnergyType = (event, newAlignment) => {
    console.log("Energytpe", event.target.value);
    setEnergyType(event.target.value);
  };

  function Pannel(Parameter, Access) {
    // let Access = Access_profiles[SharedProfile];
    // console.log(Access);
    if (Parameter === "power") {
      return (
        // <span>power</span>
        <ReactApexChart
          height={450}
          ref={chartRef}
          options={chartConfig}
          series={[
            { name: "Power_SM_L1_A", data: data },
            { name: "Power_SM_L2_A", data: data1 },
            { name: "Power_SM_L3_A", data: data2 },
            { name: "Zero_Total_Power", data: data3 },
            { name: "Power_Total_IEL_A", data: data4 },
            { name: "Power_Solis_DC_T_A", data: data4 },
            { name: "Power_Solis_AC_T_A", data: data4 },
          ]}
          type="line"
        />
      );
    } else if (Parameter === "power1") {
      return (
        // <span>power</span>
        <ReactApexChart
          height={450}
          ref={chartRef}
          options={chartConfig}
          series={[
            Access.Zero_Total_Power
              ? { name: "Load Power", data: data1 }
              : undefined,
            { name: "Solar Power", data: data2 },
          ].filter((obj) => obj !== undefined)}
          type="line"
        />
      );
    } else if (Parameter === "power2") {
      return (
        // <span>power</span>
        <ReactApexChart
          height={450}
          ref={chartRef}
          options={chartConfig}
          series={[
            { name: "Zero_Total_Power", data: data },

            phases != 1 ? { name: "Power_SM_L1_A", data: data1 } : undefined,

            phases != 1 ? { name: "Power_SM_L2_A", data: data2 } : undefined,

            phases != 1 ? { name: "Power_SM_L3_A", data: data3 } : undefined,
          ].filter((obj) => obj !== undefined)}
          type="line"
        />
      );
    } else if (Parameter === "power3") {
      return (
        // <span>power</span>
        <ReactApexChart
          height={450}
          ref={chartRef}
          options={chartConfig}
          series={[
            { name: "Power_Total_IEL_A", data: data },
            { name: "Solar Power", data: data1 },
          ]}
          type="line"
        />
      );
    } else if (Parameter == "energy") {
      return (
        <ReactApexChart
          height={450}
          ref={chartRef}
          options={chartConfig}
          series={[
            // { name: "Energy_Solis_Today", data: data },
            // { name: "Energy_Solis_Month", data: data1 },
            // { name: "Solar_Energy_Year", data: data2 },
            Access.Energy_Grid_SM_T_A
              ? { name: "Energy_Grid_SM_T_A", data: data }
              : undefined,
            Access.Accumulated_Energy_IEL
              ? { name: "Accumulated_Energy_IEL", data: data1 }
              : undefined,
            { name: "Solar Energy", data: data2 },
          ].filter((obj) => obj !== undefined)}
          type="bar"
        />
      );
    } else if (Parameter == "newEnergy") {
      return (
        <NewEnergycomponent
          device_id={device_id}
          TimeFormat={TimeFormat}
          EnergyType={EnergyType}
          EnergyYear={EnergyYear}
          EnergyMonth={EnergyMonth}
          EnergyDay={EnergyDay}
          rerAccess={profileAccess.rer}
        />
      );
    } else if (Parameter == "energy2") {
      return (
        <ReactApexChart
          height={450}
          ref={chartRef}
          options={chartConfig}
          series={[
            { name: "Accumulated_Energy_Solis", data: data },
            { name: "Accumulated_Energy_Solis", data: data1 },
            { name: "Energy_Grid_SM_T_A", data: data2 },
          ]}
          type="bar"
        />
      );
    } else if (Parameter == "energy3") {
      return (
        <ReactApexChart
          height={450}
          ref={chartRef}
          options={chartConfig}
          series={[
            { name: "Accumulated_Energy_IEL", data: data },
            { name: "Accumulated_Energy_Solis", data: data1 },
          ]}
          type="bar"
        />
      );
    } else if (Parameter == "temperature") {
      return (
        <ReactApexChart
          height={450}
          ref={chartRef}
          options={chartConfig}
          series={[{ name: "Shelter Temperature", data: data }]}
          type="line"
        />
      );
    } else if (Parameter == "bill") {
      return (
        <BillingView
          device_id={device_id}
          userID={userID}
          StatementName={StatementName}
          devicename={devicename}
        />
      );
    } else if (Parameter == "alarm") {
      return <Alarm device_id={device_id} isDeviceStatus={isDeviceStatus} setAlarm={setAlarm}
      />;
    } else if (Parameter == "site") {
      return (
        <SiteDetails device_id={device_id} edit={profileAccess.site_edit} />
      );
    } else {
      return <span>Neither</span>;
    }
    return Pannel;
  }
  const detectTimeGapsAndReturnArray = (array) => {
    const fiveMinutes = 60 * 60 * 1000; // 5 minutes in milliseconds

    for (let i = 1; i < array.length; i++) {
      const previousTime = new Date(array[i - 1][0]);
      const currentTime = new Date(array[i][0]);

      const timeDifference = currentTime - previousTime;

      if (timeDifference > fiveMinutes) {
        const minutes = Math.floor(timeDifference / (60 * 1000));
        const seconds = Math.floor((timeDifference % (60 * 1000)) / 1000);

        // console.log(`Time gap greater than 5 minutes between ${array[i - 1][0]} and ${array[i][0]}. Gap: ${minutes} minutes and ${seconds} seconds.`);

        // Insert a new element with the same timestamp and a null value just after the gap start
        array.splice(i, 0, [array[i - 1][0], null]);

        // Adjust the loop index to account for the newly inserted element
        i++;
      }
    }

    // Return the modified array
    return array;
  };

  function getChartData(powerDataFromDB, field) {
    let dNotNull = 0;
    return detectTimeGapsAndReturnArray(Object.values(powerDataFromDB).map((x) => {
      let t, d;
      t = x.date_time_A;
      d = x[field];
      if (d != null && d != 0 && d != -127) dNotNull = d;
      d = d === null ? dNotNull : d;
      d = d === -127 ? dNotNull : d;
      d = d === 0 && field === "Temp_IEL" ? dNotNull : d;
      d = field === "Power_Solis_AC_T_A" ? d / 1000 : d;
      // d = field === "Zero_Total_Power" ? d / 1000 : d;

      return [t, d];
    }))
  }
  function getData(startTime, endTime) {
    let fields;
    if (Parameter === "power") {
      fields = Power_fields;
    } else if (Parameter === "energy") {
      fields = Energy_fields;
    } else if (Parameter === "power1") {
      fields = Power_fields_2;
    } else if (Parameter === "power2") {
      fields = Power_Load;
    } else if (Parameter === "power3") {
      fields = Power_Solar;
    } else if (Parameter === "energy2") {
      fields = energy2;
    } else if (Parameter === "energy3") {
      fields = energy3;
    } else if (Parameter === "temperature") {
      fields = Temperature_fields;
    }

    // console.log(fields);

    setisLoadingGraph(true);
    let localUnit;
    if (
      Parameter === "power" ||
      Parameter === "power1" ||
      Parameter === "power2" ||
      Parameter === "power3"
    ) {
      // console.log(" get data power");
      localUnit = "KW";
      UserService.GetStandardData(
        "IEL_MOR_ARCHIVED",
        device_id, //device_id,
        "Load_Power",
        "daterange",
        startTime,
        endTime
      )
        .then((res) => {
          let powerDataFromDB = res.data.data.deviceData;
          // console.log("GEt Data!!!!!!!!!", powerDataFromDB);
          let myData;
          let myData1;
          let myData2;
          let myData3;
          let myData4;
          let myData5;
          let myData6;
          if (typeof powerDataFromDB != "undefined" && !res.data.data.error) {
            myData = getChartData(powerDataFromDB, fields[0]);
            myData1 = getChartData(powerDataFromDB, fields[1]);
            myData2 = getChartData(powerDataFromDB, fields[2]);
            myData3 = getChartData(powerDataFromDB, fields[3]);
            myData4 = getChartData(powerDataFromDB, fields[4]);
            myData4 = getChartData(powerDataFromDB, fields[5]);
            myData4 = getChartData(powerDataFromDB, fields[6]);
            // console.log(myData);
          } else {
            myData = [];
          }
          if (myData.length > 0) {
            // console.log(myData);
            setLastDataFront(myData.at(-1));
            setFirstDataBack(myData.at(0));
            setendDate(endTime);

            setData(myData);
            setData1(myData1);
            setData2(myData2);
            setData3(myData3);
            setData4(myData4);

            setChartConfig({
              chart: {
                id: "total-power-chart",
                // type: "line",
                // stacked: true,
                height: 500,
                foreColor: "#000000",
                zoom: {
                  type: "x",
                  enabled: true,
                  autoScaleYaxis: true,
                },
                toolbar: {
                  autoSelected: "zoom",
                },
              },
              tooltip: {
                enabled: true,
                color: "#000000",
                style: {
                  fontSize: "16px",
                  // fontFamily: undefined
                },
                // shared: true,
                x: {
                  formatter: function (timestamp) {
                    // console.log(val)
                    if (Parameter == "energy") {
                      if (TimeFormat === "hourly")
                        return moment(timestamp).tz("UTC").format(" DD MMMM YYYY HH:mm");
                      if (TimeFormat === "daily")
                        return moment(timestamp).tz("UTC").format("DD MMMM YYYY");
                      if (TimeFormat === "monthly")
                        return moment(timestamp).tz("UTC").format("YYYY MMMM");
                    } else {
                      return moment(timestamp).tz("UTC").format(" DD MMMM YYYY HH:mm");
                    }
                  },
                },
                y: [
                  {
                    formatter: function (y) {
                      if (typeof y !== "undefined") {
                        return y.toFixed(2) + " " + "[KW]";
                      }
                      return y;
                    },
                  },
                  {
                    formatter: function (y) {
                      if (typeof y !== "undefined") {
                        return y.toFixed(2) + " " + "[KW]";
                      }
                      return y;
                    },
                  },
                  {
                    formatter: function (y) {
                      if (typeof y !== "undefined") {
                        return y.toFixed(2) + " " + "[KW]";
                      }
                      return y;
                    },
                  },
                  {
                    formatter: function (y) {
                      if (typeof y !== "undefined") {
                        return y.toFixed(2) + " " + localUnit;
                      }
                      return y;
                    },
                  },
                ],
              },
              dataLabels: {
                enabled: false,
              },
              markers: {
                size: 0,
              },
              xaxis: {
                type: "datetime",
                tickAmount: myData.length - 1,
                tooltip: {
                  enabled: false,
                },
                labels: {
                  rotate: -90,
                  rotateAlways: true,
                  minHeight: 100,
                  maxHeight: 160,
                  show: true,
                  align: "left",
                  trim: false,
                  // formatter: function (val, timestamp) {

                  //     return moment(timestamp).tz("UTC").format("HH:MM");

                  // },
                  style: {
                    colors: ["#000000"],
                    fontSize: "15px",
                    // fontFamily: 'Helvetica, Arial, sans-serif',
                    fontWeight: 600,
                  },
                },
              },
              stroke: {
                width: 2,
              },
              colors: ["#64006C", "#F30202", "#0054F1", "#FFD93D"],
              yaxis: {
                opposite: false,
                formatter: (value) => {
                  return value;
                },
                // min: 0,
                decimalsInFloat: 2,
                forceNiceScale: true,
                axisBorder: {
                  show: true,
                  color: "#78909C",
                  offsetX: 0,
                  offsetY: 0,
                },
                axisTicks: {
                  show: true,
                  borderType: "solid",
                  color: "#78909C",
                  width: 6,
                  offsetX: 0,
                  offsetY: 0,
                },
                labels: {
                  show: true,
                  align: "right",
                  minWidth: 0,
                  maxWidth: 160,
                  style: {
                    colors: [],
                    fontSize: "15px",
                    // fontFamily: 'Helvetica, Arial, sans-serif',
                    fontWeight: 600,
                  },
                  offsetX: 0,
                  offsetY: 0,
                  rotate: 0,
                  // formatter: (value) => { return val },
                },
                crosshairs: {
                  show: true,
                  position: "back",
                  stroke: {
                    color: "#b6b6b6",
                    width: 0.6,
                    dashArray: [0, 1, 2],
                  },
                },
                tooltip: {
                  enabled: false,
                  offsetX: 0,
                },
                title: {
                  text: "[" + localUnit + "]",
                  rotate: 0,
                  offsetX: 40,
                  offsetY: -170,
                  style: {
                    color: undefined,
                    fontSize: "15px",
                    // fontFamily: 'Helvetica, Arial, sans-serif',
                    fontWeight: 600,
                    // cssClass: 'apexcharts-yaxis-title',
                  },
                },
              },
              legend: {
                showForSingleSeries: true,
                offsetX: 7,
                //  offsetY:5,
                position: "top",
                fontSize: "18px",
                //  width:1
                //  fontWeight: 800,
                markers: {
                  width: 12,
                  height: 12,
                  strokeWidth: 2,
                  strokeColor: "#fff",
                  fillColors: undefined,
                  radius: 12,
                  offsetX: -5,
                  // offsetY: 5
                },
              },
            });
          } else {
            setDataAvailable(false);
          }
          setisLoadingGraph(false);
        })
        .catch((err) => {
          setisLoadingGraph(false);
          setDataAvailable(false);
        });
    } else if (
      Parameter === "energy" ||
      Parameter === "energy2" ||
      Parameter === "energy3"
    ) {
      console.log(" get data energy");
      localUnit = "KWH";
      if (TimeFormat === "monthly") {
        startTime = moment().startOf("year").format("YYYY-MM-DD HH:mm:ss");
        endTime = moment().format("YYYY-MM-DD HH:mm:ss");
      }
      if (TimeFormat === "daily") {
        startTime = moment().subtract(30, "days").format("YYYY-MM-DD HH:mm:ss");
        endTime = moment().format("YYYY-MM-DD HH:mm:ss");
      }
      UserService.GetStandardData(
        "IEL_MOR_ARCHIVED",
        device_id, //device_id,
        "Load_Power",
        "daterange",
        startTime,
        endTime,
        0,
        TimeFormat
      )
        .then((res) => {
          let powerDataFromDB = res.data.data.deviceData;
          console.log("GEt Data!!!!!!!!!", powerDataFromDB);
          let myData;
          let myData1;
          let myData2;
          let myData3;
          let myData4;
          let myData5;
          let myData6;
          if (typeof powerDataFromDB != "undefined" && !res.data.data.error) {
            fields.forEach((element) => {
              console.log(fields);
            });
            myData = getChartData(powerDataFromDB, fields[0]);
            // myData1 = getChartData(powerDataFromDB, fields[1]);
            myData2 = getChartData(powerDataFromDB, fields[2]);
            // myData3 = getChartData(powerDataFromDB, fields[3]);
            // myData4 = getChartData(powerDataFromDB, fields[4]);
            // myData5 = getChartData(powerDataFromDB, fields[5]);
            // myData6 = getChartData(powerDataFromDB, fields[6]);
            // console.log(calculateDifferences(myData));
            console.log(myData2);
            myData = fillMissingGaps(
              calculateDifferences(getChartData(powerDataFromDB, fields[0])),
              TimeFormat
            );
            myData1 = fillMissingGaps(
              calculateDifferences(getChartData(powerDataFromDB, fields[1])),
              TimeFormat
            );
            myData2 = fillMissingGaps(
              calculateDifferences(getChartData(powerDataFromDB, fields[2])),
              TimeFormat
            );
            myData3 = fillMissingGaps(
              calculateDifferences(getChartData(powerDataFromDB, fields[3])),
              TimeFormat
            );

            // myData = calculateDifferences(getChartData(powerDataFromDB, fields[0]));
            // myData1 = calculateDifferences(getChartData(powerDataFromDB, fields[1]));
            // myData2 = calculateDifferences(getChartData(powerDataFromDB, fields[2]));
            // myData3 = calculateDifferences(getChartData(powerDataFromDB, fields[3]));

            // console.log(myData);
            console.log(myData1);
            console.log(myData2);
            // console.log(myData3);
          } else {
            myData = [];
          }
          if (myData.length > 0) {
            // console.log(myData);
            setLastDataFront(myData.at(-1));
            setFirstDataBack(myData.at(0));
            setendDate(endTime);

            setData(myData);
            setData1(myData1);
            setData2(myData2);
            setData3(myData3);
            setData4(myData4);

            setChartConfig({
              chart: {
                id: "total-power-chart",
                // type: "line",
                // stacked: true,
                height: 500,
                foreColor: "#000000",
                zoom: {
                  type: "x",
                  enabled: true,
                  autoScaleYaxis: true,
                },
                toolbar: {
                  autoSelected: "zoom",
                },
              },
              tooltip: {
                enabled: true,
                color: "#000000",
                style: {
                  fontSize: "16px",
                  // fontFamily: undefined
                },
                shared: true,
                x: {
                  formatter: function (timestamp) {
                    // console.log(val)
                    if (Parameter == "energy") {
                      if (TimeFormat === "hourly")
                        return moment(timestamp).tz("UTC").format(" DD MMMM YYYY HH:mm");
                      if (TimeFormat === "daily")
                        return moment(timestamp).tz("UTC").format("DD MMMM YYYY");
                      if (TimeFormat === "monthly")
                        return moment(timestamp).tz("UTC").format("YYYY MMMM");
                    } else {
                      return moment(timestamp).tz("UTC").format(" DD MMMM YYYY HH:mm");
                    }
                  },
                },
                y: [
                  {
                    formatter: function (y) {
                      if (typeof y !== "undefined") {
                        return y + " " + "[" + localUnit + "]";
                      }
                      return y;
                    },
                  },
                  {
                    formatter: function (y) {
                      if (typeof y !== "undefined") {
                        return y + " " + "[" + localUnit + "]";
                      }
                      return y;
                    },
                  },
                  {
                    formatter: function (y) {
                      if (typeof y !== "undefined") {
                        return y + " " + "[" + localUnit + "]";
                      }
                      return y;
                    },
                  },
                  {
                    formatter: function (y) {
                      if (typeof y !== "undefined") {
                        return y + " " + localUnit;
                      }
                      return y;
                    },
                  },
                ],
              },
              dataLabels: {
                enabled: false,
              },
              markers: {
                size: 0,
              },
              xaxis: {
                type: "datetime",
                tickAmount: myData.length - 1,
                tooltip: {
                  enabled: false,
                },
                labels: {
                  rotate: -90,
                  rotateAlways: true,
                  minHeight: 100,
                  maxHeight: 160,
                  show: true,
                  align: "left",
                  trim: false,
                  // formatter: function (val, timestamp) {
                  //   if (TimeFormat === "hourly")
                  //     return moment(timestamp).tz("UTC").format("HH:00");
                  //   if (TimeFormat === "daily")
                  //     return moment(timestamp).tz("UTC").format("DD MMM");
                  //   if (TimeFormat === "monthly")
                  //     return moment(timestamp).tz("UTC").format("MMM YYYY");
                  // },
                  style: {
                    colors: ["#000000"],
                    fontSize: "15px",
                    // fontFamily: 'Helvetica, Arial, sans-serif',
                    fontWeight: 600,
                  },
                },
              },
              stroke: {
                width: 2,
              },
              colors: ["#64006C", "#F30202", "#0054F1", "#FFD93D"],
              yaxis: {
                opposite: false,
                formatter: (value) => {
                  return value;
                },
                // min: 0,
                decimalsInFloat: 2,
                forceNiceScale: true,
                axisBorder: {
                  show: true,
                  color: "#78909C",
                  offsetX: 0,
                  offsetY: 0,
                },
                axisTicks: {
                  show: true,
                  borderType: "solid",
                  color: "#78909C",
                  width: 6,
                  offsetX: 0,
                  offsetY: 0,
                },
                labels: {
                  show: true,
                  align: "right",
                  minWidth: 0,
                  maxWidth: 160,
                  style: {
                    colors: [],
                    fontSize: "15px",
                    // fontFamily: 'Helvetica, Arial, sans-serif',
                    fontWeight: 600,
                  },
                  offsetX: 0,
                  offsetY: 0,
                  rotate: 0,
                  // formatter: (value) => { return val },
                },
                crosshairs: {
                  show: true,
                  position: "back",
                  stroke: {
                    color: "#b6b6b6",
                    width: 0.6,
                    dashArray: [0, 1, 2],
                  },
                },
                tooltip: {
                  enabled: false,
                  offsetX: 0,
                },
                title: {
                  text: "[" + localUnit + "]",
                  rotate: 0,
                  offsetX: 40,
                  offsetY: -170,
                  style: {
                    color: undefined,
                    fontSize: "15px",
                    // fontFamily: 'Helvetica, Arial, sans-serif',
                    fontWeight: 600,
                    // cssClass: 'apexcharts-yaxis-title',
                  },
                },
              },
              legend: {
                showForSingleSeries: true,
                offsetX: 7,
                //  offsetY:5,
                position: "top",
                fontSize: "18px",
                //  width:1
                //  fontWeight: 800,
                markers: {
                  width: 12,
                  height: 12,
                  strokeWidth: 2,
                  strokeColor: "#fff",
                  fillColors: undefined,
                  radius: 12,
                  offsetX: -5,
                  // offsetY: 5
                },
              },
            });
          } else {
            setDataAvailable(false);
          }
          setisLoadingGraph(false);
        })
        .catch((err) => {
          setisLoadingGraph(false);
          setDataAvailable(false);
        });
    } else if (Parameter === "temperature") {
      console.log(" get data power");
      localUnit = "C";
      UserService.GetStandardData(
        "IEL_MOR_ARCHIVED",
        device_id, //device_id,
        "Load_Power",
        "daterange",
        startTime,
        endTime
      )
        .then((res) => {
          let powerDataFromDB = res.data.data.deviceData;
          console.log("GEt Data!!!!!!!!!", powerDataFromDB);
          let myData;
          let myData1;
          let myData2;
          let myData3;
          let myData4;
          let myData5;
          let myData6;
          if (typeof powerDataFromDB != "undefined" && !res.data.data.error) {
            myData = getChartData(powerDataFromDB, fields[0]);
            myData1 = getChartData(powerDataFromDB, fields[1]);
            myData2 = getChartData(powerDataFromDB, fields[2]);
            myData3 = getChartData(powerDataFromDB, fields[3]);
            myData4 = getChartData(powerDataFromDB, fields[4]);
            myData5 = getChartData(powerDataFromDB, fields[5]);
            myData6 = getChartData(powerDataFromDB, fields[6]);
            console.log(myData);
          } else {
            myData = [];
          }
          if (myData.length > 0) {
            // console.log(myData);
            setLastDataFront(myData.at(-1));
            setFirstDataBack(myData.at(0));
            setendDate(endTime);

            setData(myData);
            setData1(myData1);
            setData2(myData2);
            setData3(myData3);
            setData4(myData4);

            setChartConfig({
              chart: {
                id: "total-power-chart",
                // type: "line",
                // stacked: true,
                height: 500,
                foreColor: "#000000",
                zoom: {
                  type: "x",
                  enabled: true,
                  autoScaleYaxis: true,
                },
                toolbar: {
                  autoSelected: "zoom",
                },
              },
              tooltip: {
                enabled: true,
                color: "#000000",
                style: {
                  fontSize: "16px",
                  // fontFamily: undefined
                },
                // shared: true,
                x: {
                  formatter: function (timestamp) {
                    // console.log(val)
                    if (Parameter == "energy") {
                      if (TimeFormat === "hourly")
                        return moment(timestamp).tz("UTC").format(" DD MMMM YYYY HH:mm");
                      if (TimeFormat === "daily")
                        return moment(timestamp).tz("UTC").format("DD MMMM YYYY");
                      if (TimeFormat === "monthly")
                        return moment(timestamp).tz("UTC").format("YYYY MMMM");
                    } else {
                      return moment(timestamp).tz("UTC").format(" DD MMMM YYYY HH:mm");
                    }
                  },
                },
                y: [
                  {
                    formatter: function (y) {
                      if (typeof y !== "undefined") {
                        return y.toFixed(2) + "[" + localUnit + "]";
                      }
                      return y;
                    },
                  },
                  {
                    formatter: function (y) {
                      if (typeof y !== "undefined") {
                        return y.toFixed(2) + "[" + localUnit + "]";
                      }
                      return y;
                    },
                  },
                  {
                    formatter: function (y) {
                      if (typeof y !== "undefined") {
                        return y.toFixed(2) + "[" + localUnit + "]";
                      }
                      return y;
                    },
                  },
                  {
                    formatter: function (y) {
                      if (typeof y !== "undefined") {
                        return y.toFixed(2) + localUnit;
                      }
                      return y;
                    },
                  },
                ],
              },
              dataLabels: {
                enabled: false,
              },
              markers: {
                size: 0,
              },
              xaxis: {
                type: "datetime",
                tickAmount: myData.length - 1,
                tooltip: {
                  enabled: false,
                },
                labels: {
                  rotate: -90,
                  rotateAlways: true,
                  minHeight: 100,
                  maxHeight: 160,
                  show: true,
                  align: "left",
                  // trim: true,
                  // formatter: function (val, timestamp) {
                  //   if (TimeFormat === "hourly")
                  //     return moment(timestamp).tz("UTC").format("HH:00");
                  //   if (TimeFormat === "daily")
                  //     return moment(timestamp).tz("UTC").format("DD MMM");
                  //   if (TimeFormat === "monthly")
                  //     return moment(timestamp).tz("UTC").format("MMM YYYY");
                  // },
                  style: {
                    colors: ["#000000"],
                    fontSize: "15px",
                    // fontFamily: 'Helvetica, Arial, sans-serif',
                    fontWeight: 600,
                  },
                },
              },
              stroke: {
                width: 2,
              },
              colors: ["#64006C", "#F30202", "#0054F1", "#FFD93D"],
              yaxis: {
                opposite: false,
                formatter: (value) => {
                  return value;
                },
                // min: 0,
                decimalsInFloat: 2,
                forceNiceScale: true,
                axisBorder: {
                  show: true,
                  color: "#78909C",
                  offsetX: 0,
                  offsetY: 0,
                },
                axisTicks: {
                  show: true,
                  borderType: "solid",
                  color: "#78909C",
                  width: 6,
                  offsetX: 0,
                  offsetY: 0,
                },
                labels: {
                  show: true,
                  align: "right",
                  minWidth: 0,
                  maxWidth: 160,
                  style: {
                    colors: [],
                    fontSize: "15px",
                    // fontFamily: 'Helvetica, Arial, sans-serif',
                    fontWeight: 600,
                  },
                  offsetX: 0,
                  offsetY: 0,
                  rotate: 0,
                  // formatter: (value) => { return val },
                },
                crosshairs: {
                  show: true,
                  position: "back",
                  stroke: {
                    color: "#b6b6b6",
                    width: 0.6,
                    dashArray: [0, 1, 2],
                  },
                },
                tooltip: {
                  enabled: false,
                  offsetX: 0,
                },
                title: {
                  text: "[" + localUnit + "]",
                  rotate: 0,
                  offsetX: 40,
                  offsetY: -170,
                  style: {
                    color: undefined,
                    fontSize: "15px",
                    // fontFamily: 'Helvetica, Arial, sans-serif',
                    fontWeight: 600,
                    // cssClass: 'apexcharts-yaxis-title',
                  },
                },
              },
              legend: {
                showForSingleSeries: true,
                offsetX: 7,
                //  offsetY:5,
                position: "top",
                fontSize: "18px",
                //  width:1
                //  fontWeight: 800,
                markers: {
                  width: 12,
                  height: 12,
                  strokeWidth: 2,
                  strokeColor: "#fff",
                  fillColors: undefined,
                  radius: 12,
                  offsetX: -5,
                  // offsetY: 5
                },
              },
            });
          } else {
            setDataAvailable(false);
          }
          setisLoadingGraph(false);
        })
        .catch((err) => {
          setisLoadingGraph(false);
          setDataAvailable(false);
        });
    }
  }

  function getDataFront(interval) {
    let fields = [
      "Power_SM_L1_A",
      "Power_SM_L2_A",
      "Power_SM_L3_A",
      "Zero_Total_Power",
      "Energy_Grid_SM_T_A",
    ];

    // let startTime = moment(FirstDataBack[0])
    //   .subtract(interval, "days")
    //   .format("YYYY-MM-DD hh:mm:ss");
    // let endTime = FirstDataBack[0];
    console.log(LastDataFront[0], FirstDataBack[0]);
    let endTime = moment(LastDataFront[0])
      .add(interval, "days")
      .format("YYYY-MM-DD HH:mm:ss");
    let startTime = FirstDataBack[0];

    console.log(startTime, endTime);

    setisLoadingGraph(true);
    let localUnit;
    if (Parameter === "power") {
      // console.log(" get data power");
      localUnit = "KW";
      UserService.GetLinkedDeviceTemperatureData(
        "IEL-DC-ce28cc", //device_id,
        "Load_Power",
        "daterange",
        startTime,
        endTime
      )
        .then((res) => {
          let powerDataFromDB = res.data.data.deviceData;
          // console.log("Data Back!!!!!!!!!", powerDataFromDB);
          let myData;
          let myData1;
          let myData2;
          let myData3;
          let myData4;
          let myData5;
          let myData6;
          if (typeof powerDataFromDB != "undefined" && !res.data.data.error) {
            myData = getChartData(powerDataFromDB, fields[0]);
            myData1 = getChartData(powerDataFromDB, fields[1]);
            myData2 = getChartData(powerDataFromDB, fields[2]);
            myData3 = getChartData(powerDataFromDB, fields[3]);
            myData4 = getChartData(powerDataFromDB, fields[4]);
            myData5 = getChartData(powerDataFromDB, fields[5]);
            myData6 = getChartData(powerDataFromDB, fields[6]);
          } else {
            myData = [];
            console.log("????");
          }
          if (myData.length > 0) {
            // console.log(myData);

            setData((data) => data.concat(myData));
            setData1((data) => data.concat(myData1));
            setData2((data) => data.concat(myData2));

            setLastDataFront(myData.at(-1));
            setFirstDataBack(myData.at(0));
            setendDate(endTime);
            // setData1(myData1);
            // setData2(myData2);

            setChartConfig({
              chart: {
                id: "total-power-chart",
                // type: "line",
                // stacked: true,
                height: 500,
                foreColor: "#000000",
                zoom: {
                  type: "x",
                  enabled: true,
                  autoScaleYaxis: true,
                },
                toolbar: {
                  autoSelected: "zoom",
                },
              },
              tooltip: {
                enabled: true,
                color: "#000000",
                style: {
                  fontSize: "16px",
                  // fontFamily: undefined
                },
                // shared: true,
                x: {
                  format: "dd MMM yyyy hh:mm TT",
                },
                y: [
                  {
                    formatter: function (y) {
                      if (typeof y !== "undefined") {
                        return y.toFixed(2) + " " + localUnit;
                      }
                      return y;
                    },
                  },
                  {
                    formatter: function (y) {
                      if (typeof y !== "undefined") {
                        return y.toFixed(2) + " " + localUnit;
                      }
                      return y;
                    },
                  },
                  {
                    formatter: function (y) {
                      if (typeof y !== "undefined") {
                        return y.toFixed(2) + " " + localUnit;
                      }
                      return y;
                    },
                  },
                  {
                    formatter: function (y) {
                      if (typeof y !== "undefined") {
                        return y.toFixed(2) + " " + localUnit;
                      }
                      return y;
                    },
                  },
                ],
              },
              dataLabels: {
                enabled: false,
              },
              markers: {
                size: 0,
              },
              xaxis: {
                type: "datetime",
                tickAmount: myData.length - 1,
                tooltip: {
                  enabled: false,
                },
                labels: {
                  rotate: -90,
                  rotateAlways: true,
                  minHeight: 100,
                  maxHeight: 160,
                  show: true,
                  align: "left",
                  // trim: true,
                  // formatter: function (val, timestamp) {
                  //   if (TimeFormat === "hourly")
                  //     return moment(timestamp).tz("UTC").format("HH:00");
                  //   if (TimeFormat === "daily")
                  //     return moment(timestamp).tz("UTC").format("DD MMM");
                  //   if (TimeFormat === "monthly")
                  //     return moment(timestamp).tz("UTC").format("MMM YYYY");
                  // },
                  style: {
                    colors: ["#000000"],
                    fontSize: "15px",
                    // fontFamily: 'Helvetica, Arial, sans-serif',
                    fontWeight: 600,
                  },
                },
              },
              stroke: {
                width: 2,
              },
              // colors: ["#64006C",  "#0054F1", "#00FF00", "#FFD93D"],
              colors: ["#64006C", "#0054F1", "#00FF00", "#FFD93D"],
              yaxis: {
                opposite: false,
                formatter: (value) => {
                  return value;
                },
                min: 0,
                decimalsInFloat: 2,
                forceNiceScale: true,
                axisBorder: {
                  show: true,
                  color: "#78909C",
                  offsetX: 0,
                  offsetY: 0,
                },
                axisTicks: {
                  show: true,
                  borderType: "solid",
                  color: "#78909C",
                  width: 6,
                  offsetX: 0,
                  offsetY: 0,
                },
                labels: {
                  show: true,
                  align: "right",
                  minWidth: 0,
                  maxWidth: 160,
                  style: {
                    colors: [],
                    fontSize: "15px",
                    // fontFamily: 'Helvetica, Arial, sans-serif',
                    fontWeight: 600,
                  },
                  offsetX: 0,
                  offsetY: 0,
                  rotate: 0,
                  // formatter: (value) => { return val },
                },
                crosshairs: {
                  show: true,
                  position: "back",
                  stroke: {
                    color: "#b6b6b6",
                    width: 0.6,
                    dashArray: [0, 1, 2],
                  },
                },
                tooltip: {
                  enabled: false,
                  offsetX: 0,
                },
                title: {
                  text: "[" + localUnit + "]",
                  rotate: 0,
                  offsetX: 40,
                  offsetY: -170,
                  style: {
                    color: undefined,
                    fontSize: "15px",
                    // fontFamily: 'Helvetica, Arial, sans-serif',
                    fontWeight: 600,
                    // cssClass: 'apexcharts-yaxis-title',
                  },
                },
              },
              legend: {
                showForSingleSeries: true,
                offsetX: 7,
                //  offsetY:5,
                position: "top",
                fontSize: "18px",
                //  width:1
                //  fontWeight: 800,
                markers: {
                  width: 12,
                  height: 12,
                  strokeWidth: 2,
                  strokeColor: "#fff",
                  fillColors: undefined,
                  radius: 12,
                  offsetX: -5,
                  // offsetY: 5
                },
              },
            });
          } else {
            setDataAvailable(false);
          }
          setisLoadingGraph(false);
        })
        .catch((err) => {
          setisLoadingGraph(false);
          setDataAvailable(false);
        });
    }
  }

  function getDataBack(interval) {
    let fields = [
      "Power_SM_L1_A",
      "Power_SM_L2_A",
      "Power_SM_L3_A",
      "Zero_Total_Power",
      "Energy_Grid_SM_T_A",
    ];

    let startTime = moment(FirstDataBack[0])
      .subtract(interval, "days")
      .format("YYYY-MM-DD HH:mm:ss");
    let endTime = FirstDataBack[0];
    console.log(startTime, endTime);

    setisLoadingGraph(true);
    let localUnit;
    if (Parameter === "power") {
      // console.log(" get data power");
      localUnit = "KW";
      UserService.GetLinkedDeviceTemperatureData(
        "IEL-DC-ce28cc", //device_id,
        "Load_Power",
        "daterange",
        startTime,
        endTime
      )
        .then((res) => {
          let powerDataFromDB = res.data.data.deviceData;
          // console.log("Data Back!!!!!!!!!", powerDataFromDB);
          let myData;
          let myData1;
          let myData2;
          let myData3;
          let myData4;
          let myData5;
          let myData6;
          if (typeof powerDataFromDB != "undefined" && !res.data.data.error) {
            myData = getChartData(powerDataFromDB, fields[0]);
            myData1 = getChartData(powerDataFromDB, fields[1]);
            myData2 = getChartData(powerDataFromDB, fields[2]);
            myData3 = getChartData(powerDataFromDB, fields[3]);
            myData4 = getChartData(powerDataFromDB, fields[4]);
            myData5 = getChartData(powerDataFromDB, fields[5]);
            myData6 = getChartData(powerDataFromDB, fields[6]);
          } else {
            myData = [];
            console.log("????");
          }
          if (myData.length > 0) {
            // console.log(myData);

            setData((data) => myData.concat(data));
            setData1((data) => myData1.concat(data));
            setData2((data) => myData2.concat(data));
            setData3((data) => myData3.concat(data));
            setData4((data) => myData4.concat(data));
            setData5((data) => myData5.concat(data));
            setData6((data) => myData6.concat(data));

            setLastDataFront(myData.at(-1));
            setFirstDataBack(myData.at(0));
            setendDate(endTime);
            // setData1(myData1);
            // setData2(myData2);

            setChartConfig({
              chart: {
                id: "total-power-chart",
                // type: "line",
                // stacked: true,
                height: 500,
                foreColor: "#000000",
                zoom: {
                  type: "x",
                  enabled: true,
                  autoScaleYaxis: true,
                },
                toolbar: {
                  autoSelected: "zoom",
                },
              },
              tooltip: {
                enabled: true,
                color: "#000000",
                style: {
                  fontSize: "16px",
                  // fontFamily: undefined
                },
                // shared: true,
                x: {
                  format: "dd MMM yyyy hh:mm TT",
                },
                y: [
                  {
                    formatter: function (y) {
                      if (typeof y !== "undefined") {
                        return y.toFixed(2) + " " + localUnit;
                      }
                      return y;
                    },
                  },
                  {
                    formatter: function (y) {
                      if (typeof y !== "undefined") {
                        return y.toFixed(2) + " " + localUnit;
                      }
                      return y;
                    },
                  },
                  {
                    formatter: function (y) {
                      if (typeof y !== "undefined") {
                        return y.toFixed(2) + " " + localUnit;
                      }
                      return y;
                    },
                  },
                  {
                    formatter: function (y) {
                      if (typeof y !== "undefined") {
                        return y.toFixed(2) + " " + localUnit;
                      }
                      return y;
                    },
                  },
                ],
              },
              dataLabels: {
                enabled: false,
              },
              markers: {
                size: 0,
              },
              xaxis: {
                type: "datetime",
                tickAmount: myData.length - 1,
                tooltip: {
                  enabled: false,
                },
                labels: {
                  rotate: -90,
                  rotateAlways: true,
                  minHeight: 100,
                  maxHeight: 160,
                  show: true,
                  align: "left",
                  // trim: true,
                  // formatter: function (val, timestamp) {
                  //   if (TimeFormat === "hourly")
                  //     return moment(timestamp).tz("UTC").format("HH:00");
                  //   if (TimeFormat === "daily")
                  //     return moment(timestamp).tz("UTC").format("DD MMM");
                  //   if (TimeFormat === "monthly")
                  //     return moment(timestamp).tz("UTC").format("MMM YYYY");
                  // },
                  style: {
                    colors: ["#000000"],
                    fontSize: "15px",
                    // fontFamily: 'Helvetica, Arial, sans-serif',
                    fontWeight: 600,
                  },
                },
              },
              stroke: {
                width: 2,
              },
              // colors: ["#64006C",  "#0054F1", "#00FF00", "#FFD93D"],
              colors: ["#64006C", "#0054F1", "#00FF00", "#FFD93D"],
              yaxis: {
                opposite: false,
                formatter: (value) => {
                  return value;
                },
                min: 0,
                decimalsInFloat: 2,
                forceNiceScale: true,
                axisBorder: {
                  show: true,
                  color: "#78909C",
                  offsetX: 0,
                  offsetY: 0,
                },
                axisTicks: {
                  show: true,
                  borderType: "solid",
                  color: "#78909C",
                  width: 6,
                  offsetX: 0,
                  offsetY: 0,
                },
                labels: {
                  show: true,
                  align: "right",
                  minWidth: 0,
                  maxWidth: 160,
                  style: {
                    colors: [],
                    fontSize: "15px",
                    // fontFamily: 'Helvetica, Arial, sans-serif',
                    fontWeight: 600,
                  },
                  offsetX: 0,
                  offsetY: 0,
                  rotate: 0,
                  // formatter: (value) => { return val },
                },
                crosshairs: {
                  show: true,
                  position: "back",
                  stroke: {
                    color: "#b6b6b6",
                    width: 0.6,
                    dashArray: [0, 1, 2],
                  },
                },
                tooltip: {
                  enabled: false,
                  offsetX: 0,
                },
                title: {
                  text: "[" + localUnit + "]",
                  rotate: 0,
                  offsetX: 40,
                  offsetY: -170,
                  style: {
                    color: undefined,
                    fontSize: "15px",
                    // fontFamily: 'Helvetica, Arial, sans-serif',
                    fontWeight: 600,
                    // cssClass: 'apexcharts-yaxis-title',
                  },
                },
              },
              legend: {
                showForSingleSeries: true,
                offsetX: 7,
                //  offsetY:5,
                position: "top",
                fontSize: "18px",
                //  width:1
                //  fontWeight: 800,
                markers: {
                  width: 12,
                  height: 12,
                  strokeWidth: 2,
                  strokeColor: "#fff",
                  fillColors: undefined,
                  radius: 12,
                  offsetX: -5,
                  // offsetY: 5
                },
              },
            });
          } else {
            setDataAvailable(false);
          }
          setisLoadingGraph(false);
        })
        .catch((err) => {
          setisLoadingGraph(false);
          setDataAvailable(false);
        });
    }
  }

  function getProcessData(device_id) {
    // console.log("get process");
    UserService.GetStandardData(
      "IEL_MOR_PROCESS",
      device_id, //device_id,
      "Load_Power",
      "daterange",
      "2000-01-01",
      "2099-01-01"
    )
      .then((res) => {
        let powerDataFromDB = res.data.data.deviceData[0];
        console.log("***Process Data!!!!!!!!!", powerDataFromDB);
        setStaticValue1((powerDataFromDB.Power_Solis_AC_T / 1000).toFixed(2)); //p29
        // setStaticValue2(0);//a10
        // setStaticValue3(0);//
        setStaticValue4(powerDataFromDB.Frequency_Solis.toFixed(2)); // p20
        setStaticValue5(powerDataFromDB.Power_Factor_SM.toFixed(2)); //p30

        setStaticValue11(
          powerDataFromDB.Voltage_Solis_DC_T === null
            ? 0
            : powerDataFromDB.Voltage_Solis_DC_T.toFixed(1)
        ); // p20
        setStaticValue12((powerDataFromDB.Power_Solis_DC_T / 1000).toFixed(2)); // p20
        setStaticValue15(powerDataFromDB.Power_SM_T.toFixed(2)); // p20

        // setStaticValue6(powerDataFromDB.temperature.toFixed(1));
        // let x =
        //   powerDataFromDB.temperature === null
        //     ? 0
        //     : powerDataFromDB.temperature.toFixed(1);
        // setStaticValue6(x); // read from archived

        // setStaticValue7(
        //   powerDataFromDB.Solar1_Intrusion + powerDataFromDB.Solar2_Intrusion
        // );

        // setStaticValue6(0);

        setPhase11({
          voltage: powerDataFromDB.Voltage_Solis_Ph1,
          current: powerDataFromDB.Current_Solis_Ph1,
          power: powerDataFromDB.Power_Solis_AC_T / 3,
        });
        setPhase12({
          voltage: powerDataFromDB.Voltage_Solis_Ph2,
          current: powerDataFromDB.Current_Solis_Ph2,
          power: powerDataFromDB.Power_Solis_AC_T / 3,
        });
        setPhase13({
          voltage: powerDataFromDB.Voltage_Solis_Ph3,
          current: powerDataFromDB.Current_Solis_Ph3,
          power: powerDataFromDB.Power_Solis_AC_T / 3,
        });

        if (phases == 1)
          setPhase13({
            voltage: powerDataFromDB.Voltage_SM_L1,
            current: powerDataFromDB.Current_Solis_Ph3,
            power: powerDataFromDB.Power_Solis_AC_T / 3,
          });

        setPhase21({
          voltage: powerDataFromDB.Voltage_IEL_L1,
          current: powerDataFromDB.Current_IEL_L1,
          power: powerDataFromDB.Power_IEL_L1,
        });
        setPhase22({
          voltage: powerDataFromDB.Voltage_IEL_L2,
          current: powerDataFromDB.Current_IEL_L2,
          power: powerDataFromDB.Power_IEL_L2,
        });
        setPhase23({
          voltage: powerDataFromDB.Voltage_IEL_L3,
          current: powerDataFromDB.Current_IEL_L3,
          power: powerDataFromDB.Power_IEL_L3,
        });

        if (phases == 1) {
          setPhase21({
            voltage: powerDataFromDB.Voltage_SM_L1.toFixed(2),
            current: powerDataFromDB.Current_SM_L3.toFixed(2),
            power: powerDataFromDB.Power_SM_T.toFixed(2),
          });
        }

        setPhase31({
          voltage: powerDataFromDB.Voltage_SM_L1.toFixed(2),
          current: powerDataFromDB.Current_SM_L1.toFixed(2),
          power: powerDataFromDB.Power_SM_L1.toFixed(2),
        });
        setPhase32({
          voltage: powerDataFromDB.Voltage_SM_L2.toFixed(2),
          current: powerDataFromDB.Current_SM_L2.toFixed(2),
          power: powerDataFromDB.Power_SM_L2.toFixed(2),
        });
        setPhase33({
          voltage: powerDataFromDB.Voltage_SM_L3.toFixed(2),
          current: powerDataFromDB.Current_SM_L3.toFixed(2),
          power: powerDataFromDB.Power_SM_L3.toFixed(2),
        });
      })
      .catch((err) => {
        console.log(err);
      });

    UserService.GetStandardData(
      "IEL_MOR_ARCHIVED",
      device_id, //device_id,
      "Load_Power",
      "daterange",
      moment().format("YYYY-MM-DD HH:mm:ss"),
      "2099-01-01",
      1
    )
      .then((res) => {
        let powerDataFromDB = res.data.data.deviceData[0];
        // console.log("Archived res Data!!!!!!!!!", powerDataFromDB);
        // console.log("Archived2 res Data!!!!!!!!!", res.data.data.deviceData[0]);
        // setStaticValue1(powerDataFromDB.Power_Solis_AC_T); //p29
        setStaticValue2(powerDataFromDB.Energy_Solis_Today.toFixed(1)); //a10
        setStaticValue3(powerDataFromDB.Energy_Solis_Month.toFixed(1)); //p42
        // setStaticValue4(powerDataFromDB.Power_Factor_SM);
        // setStaticValue5(0);//P30
        // setStaticValue6(powerDataFromDB.Temp_IEL); //a5
        let x =
          powerDataFromDB.Temp_IEL === null
            ? 0
            : powerDataFromDB.Temp_IEL.toFixed(1);
        setStaticValue6(x); // read from archived

        setStaticValue7(
          powerDataFromDB.Solar1_Intrusion + powerDataFromDB.Solar2_Intrusion
        );

        setStaticValue13(powerDataFromDB.Accumulated_Energy_Solis.toFixed(2));
        setStaticValue14(
          (powerDataFromDB.Accumulated_Energy_Solis * 0.0006956).toFixed(2)
        );
        setStaticValue16(powerDataFromDB.Energy_Grid_SM_T_A.toFixed(2));
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    const interval = setInterval(() => {
      console.log("Refsh 40 second");
      console.log("setInterval", Parameter);
      let endTime = moment().format("YYYY-MM-DD HH:mm:ss");
      let startTime = moment()
        .subtract(5, "days")
        .format("YYYY-MM-DD HH:mm:ss");
      // console
      getProcessData(device_id);
      UserService.GetAccessProfileById(SharedProfile)
        .then((res) => {
          // console.log("!!!!!!!!!!!!!!!!!!!!********** List:", res.data.data);
          // setPayload(res.data.data.deviceData[0]);
          const result = res.data.data.devices[0];
          // console.log(result);
          setProfileAccess(result);
          // console.log("ProfileAccess", profileAccess);
        })
        .catch((err) => {
          console.log(err);
        });

      UserService.checkDeviceID(device_id)
        .then((res) => {
          let DataFromDB = res.data.data[0];

          console.log("no_of_phases", DataFromDB);
          console.log("no_of_phases", DataFromDB["no_of_phases"]);
          if (DataFromDB["no_of_phases"] === "Single") setPhases(1);
          else setPhases(3);
        })
        .catch((err) => {
          console.log(err);
        });

      if (Rt) getData(startTime, endTime);
    }, 30 * 1000);
    return () => clearInterval(interval);
  }, [
    TimeFormat,
    Parameter,
    device_id,
    Rt,
    EnergyMonth,
    EnergyYear,
    EnergyType,
  ]);

  useEffect(() => {
    // getDashboardData(device_id);
    // let fields = [
    //   "Power_SM_L1_A",
    //   "Power_SM_L2_A",
    //   "Power_SM_L3_A",
    //   "Zero_Total_Power",
    //   "Energy_Grid_SM_T_A",
    // ];
    let endTime = moment().format("YYYY-MM-DD HH:mm:ss");
    let startTime = moment().subtract(5, "days").format("YYYY-MM-DD HH:mm:ss");
    // console
    getData(startTime, endTime);
    getProcessData(device_id);

    //get profile access
    UserService.GetAccessProfileById(SharedProfile)
      .then((res) => {
        console.log("!!!!!!!!!!!!!!!!!!!!********** List:", res.data.data);
        // setPayload(res.data.data.deviceData[0]);
        const result = res.data.data.devices[0];
        // console.log(result);
        setProfileAccess(result);
        // console.log("ProfileAccess", profileAccess);
      })
      .catch((err) => {
        console.log(err);
      });
    UserService.checkDeviceID(device_id)
      .then((res) => {
        let DataFromDB = res.data.data[0];

        console.log("no_of_phases", DataFromDB);
        console.log("no_of_phases", DataFromDB["no_of_phases"]);
        if (DataFromDB["no_of_phases"] === "Single") setPhases(1);
        else setPhases(3);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    // getDashboardData(device_id);
    // let fields = [
    //   "Power_SM_L1_A",
    //   "Power_SM_L2_A",
    //   "Power_SM_L3_A",
    //   "Zero_Total_Power",
    //   "Energy_Grid_SM_T_A",
    // ];
    setParameter("power1");

    let endTime = moment().format("YYYY-MM-DD HH:mm:ss");
    let startTime = moment().subtract(5, "days").format("YYYY-MM-DD HH:mm:ss");
    // console
    getData(startTime, endTime);
    getProcessData(device_id);

    UserService.GetAccessProfileById(SharedProfile)
      .then((res) => {
        // console.log("!!!!!!!!!!!!!!!!!!!!********** List:", res.data.data);
        // setPayload(res.data.data.deviceData[0]);
        const result = res.data.data.devices[0];
        // console.log(result);
        setProfileAccess(result);
        // console.log("ProfileAccess", profileAccess);
      })
      .catch((err) => {
        console.log(err);
      });
    UserService.checkDeviceID(device_id)
      .then((res) => {
        let DataFromDB = res.data.data[0];

        console.log("no_of_phases", DataFromDB);
        console.log("no_of_phases", DataFromDB["no_of_phases"]);
        if (DataFromDB["no_of_phases"] === "Single") setPhases(1);
        else setPhases(3);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [device_id]);

  useEffect(() => {
    // console.log("changer param", Parameter);
    let endTime = moment().format("YYYY-MM-DD HH:mm:ss");
    let startTime = moment().subtract(5, "days").format("YYYY-MM-DD HH:mm:ss");
    getData(startTime, endTime);
    getProcessData(device_id);

    UserService.GetAccessProfileById(SharedProfile)
      .then((res) => {
        // console.log("!!!!!!!!!!!!!!!!!!!!********** List:", res.data.data);
        // setPayload(res.data.data.deviceData[0]);
        const result = res.data.data.devices[0];
        // console.log(result);
        setProfileAccess(result);
        // console.log("ProfileAccess", profileAccess);
      })
      .catch((err) => {
        console.log(err);
      });
    UserService.checkDeviceID(device_id)
      .then((res) => {
        let DataFromDB = res.data.data[0];

        console.log("no_of_phases", DataFromDB);
        console.log("no_of_phases", DataFromDB["no_of_phases"]);
        if (DataFromDB["no_of_phases"] === "Single") setPhases(1);
        else setPhases(3);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [Parameter, TimeFormat, device_id, EnergyMonth, EnergyYear, EnergyType]);

  const handleChangeFormat = (event) => {
    setTtimeFormat(event.target.value);
    console.log(event.target.value);
    // handleApply();
  };

  const handleApply = (event, picker) => {
    console.log("handle Apply");
    let startDateObj = picker.startDate._d;
    let endDateObj = picker.endDate._d;
    let startTime = moment(startDateObj).format("YYYY-MM-DD HH:mm:ss");
    let endTime = moment(endDateObj).format("YYYY-MM-DD 23:59:59");
    console.log("startDateObjDate", startTime);
    console.log("endDateObjDate", endTime);

    let fields = [
      "Power_SM_L1_A",
      "Power_SM_L2_A",
      "Power_SM_L3_A",
      "Zero_Total_Power",
      "Energy_Grid_SM_T_A",
    ];

    if (Parameter === "power") {
      getData(startTime, endTime, Parameter, Power_fields);
    } else if (Parameter === "energy") {
      getData(startTime, endTime, Parameter, Energy_fields);
    } else if (Parameter === "power1") {
      console.log("power1");
      getData(startTime, endTime, Parameter, Power_fields_2);
    } else if (Parameter === "power2") {
      getData(startTime, endTime, Parameter, Power_Load);
    } else if (Parameter === "power3") {
      getData(startTime, endTime, Parameter, Power_Solar);
    } else if (Parameter === "energy2") {
      getData(startTime, endTime, Parameter, energy2);
    } else if (Parameter === "energy3") {
      getData(startTime, endTime, Parameter, energy3);
    } else if (Parameter === "temperature") {
      getData(startTime, endTime, Parameter, Temperature_fields);
    }
  };

  const handleBackIClick = (event) => {
    console.log("handleBackIClick");
    getDataBack(5);
  };

  const handleForwardClick = (event) => {
    console.log("handleForwardClick");
    // handleApply();
    getDataFront(5);
  };

  const get_Site_Detail = () => {
    console.log("get process");

    UserService.checkDeviceID(device_id)
      .then((res) => {
        let DataFromDB = res.data.data[0];

        const { device_id, deployment_date, capacity_per_panel } = DataFromDB;
        console.log({ device_id, deployment_date, capacity_per_panel })
        return [device_id, deployment_date, capacity_per_panel]
      })
      .catch((err) => {
        console.log(err);
      });
  }
  function renameFieldInArray(arr, oldFieldName, newFieldName) {
    return arr.map(obj => {
      // Create a new object with the rest of the fields
      const newObj = { ...obj };
      // If the old field exists, rename it
      if (oldFieldName in newObj) {
        newObj[newFieldName] = newObj[oldFieldName];
        delete newObj[oldFieldName]; // Remove the old field
      }
      return newObj;
    });
  }

  function mergeArraysByMonthIndex(arr1, arr2) {
    const mergedResult = [];

    // Combine both arrays
    const combined = [...arr1, ...arr2];

    // Create a map to store objects by month_index
    const monthIndexMap = {};

    combined.forEach((obj) => {
      const { month_index, ...fields } = obj;

      // Check if month_index already exists in the map
      if (monthIndexMap[month_index]) {
        // If exists, merge fields (add missing fields with null values)
        Object.keys(fields).forEach((key) => {
          monthIndexMap[month_index][key] = fields[key];
        });
      } else {
        // If not, add a new entry to the map
        monthIndexMap[month_index] = { month_index, ...fields };
      }

      // Add 'solid' and 'load' with default value null if not present
      monthIndexMap[month_index].solis = monthIndexMap[month_index].solis ?? null;
      monthIndexMap[month_index].load = monthIndexMap[month_index].load ?? null;
    });

    // After combining, ensure all objects have consistent fields
    Object.values(monthIndexMap).forEach((obj) => {
      // Merge missing fields from both arrays, set missing ones to null
      const allFields = new Set([
        ...Object.keys(arr1[0] || {}),
        ...Object.keys(arr2[0] || {}),
        'solis', 'load'  // Ensure 'solid' and 'load' fields are present
      ]);

      allFields.forEach((field) => {
        if (!obj.hasOwnProperty(field)) {
          obj[field] = null;
        }
      });

      mergedResult.push(obj);
    });

    return mergedResult;
  }

  function mergeArraysByDayIndex(arr1, arr2) {
    const mergedResult = [];

    // Combine both arrays
    const combined = [...arr1, ...arr2];

    // Create a map to store objects by day_index
    const dayIndexMap = {};

    combined.forEach((obj) => {
      const { day_index, ...fields } = obj;

      // Check if day_index already exists in the map
      if (dayIndexMap[day_index]) {
        // If exists, merge fields (add missing fields with null values)
        Object.keys(fields).forEach((key) => {
          dayIndexMap[day_index][key] = fields[key];
        });
      } else {
        // If not, add a new entry to the map
        dayIndexMap[day_index] = { day_index, ...fields };
      }

      // Add 'solis' and 'load' with default value null if not present
      dayIndexMap[day_index].solis = dayIndexMap[day_index].solis ?? null;
      dayIndexMap[day_index].load = dayIndexMap[day_index].load ?? null;
    });

    // After combining, ensure all objects have consistent fields
    Object.values(dayIndexMap).forEach((obj) => {
      // Merge missing fields from both arrays, set missing ones to null
      const allFields = new Set([
        ...Object.keys(arr1[0] || {}),
        ...Object.keys(arr2[0] || {}),
        'solis', 'load'  // Ensure 'solis' and 'load' fields are present
      ]);

      allFields.forEach((field) => {
        if (!obj.hasOwnProperty(field)) {
          obj[field] = null;
        }
      });

      mergedResult.push(obj);
    });

    return mergedResult;
  }

  function getCurrentDateTime() {
    const now = new Date();

    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');

    return `${year}_${month}_${day}_${hours}_${minutes}_${seconds}`;
}

  const Excel = () => {
    console.log("excel Generation");
    console.log("get process");
    let type;
    if (TimeFormat == "daily") type = "day";
    else if (TimeFormat == "hour") type = "hour";
    else type = "month";
    // const TimeFormat = TimeFormat;
    let file_name = `${device_id}_${type}_${getCurrentDateTime()}.xlsx`
    const payload = {
      deviceId: device_id,
      type,
      year: EnergyYear,
      month: EnergyMonth,
      day: EnergyDay,
      TimeFormat: TimeFormat,
      isVirtual : 0
    };

    UserService.PostEnergyDataTable(payload)
      .then((resData) => {
        if (resData.data.type === "success") {
          let res = Object.values(resData.data.data.deviceData);
       //   console.table(res);
          const daysInMonth = moment(`${EnergyYear +2000}-${EnergyMonth}`, "YYYY-MM").daysInMonth()+1;
          let en_1 = Object.values(res).filter(x => x.energy_type == '1' && x.day_index <daysInMonth );
          let en_4 = Object.values(res).filter(x => x.energy_type == '4' && x.day_index < daysInMonth );


          let res1 = Object.values(renameFieldInArray(en_1, "value", "solis"));
          //console.log("enr 1", res1);

          let res2 = Object.values(renameFieldInArray(en_4, "value", "load"));
          console.log("enr 4", res2);
          let mergedResult;
          if (type == "month") { mergedResult = mergeArraysByMonthIndex(res1, res2); }
          if (type == "day") { mergedResult = mergeArraysByDayIndex(res1, res2); }

          // console.table(mergedResult);
          let data = (mergedResult.map(x => [
            x.site_id,
            x.state,
            type == "day" ? x.day_index + " " + months_name[x.month_index] + " " + (x.year_index + 2000) : months_name[x.month_index] + " " + (x.year_index + 2000),
            "Outdoor",
            x.capacity_per_panel,
            x.load,
            x.solis,
            (x.solis * 0.0006956 * 10000) / 1000,
            (x.solis !== null && x.load !== null && x.load !== 0) ? ((x.solis / x.load) * 100).toFixed(2) + "%" : null,
            x.deployment_date

          ]))
        //  console.table(data);
          const header = ["Site", "Région", "Date", "Site Type", "Site Capacity (kWp)", "AC Supply  (kWh),", "Solar Supply  (kWh)", "CO2 (ton)", "RER (%)", "Date Intégration"];
          const footer = [
            "Total",
            "",
            "",
            "",
            "",
            data.reduce((sum, innerArray) => {
              if (innerArray[5] === null) return null; // Return null if any value at index 5 is null
              return sum + (innerArray[5] || 0);
            }, 0),
            data.reduce((sum, innerArray) => {
              return sum + (innerArray[6] || 0);
            }, 0).toFixed(2),
            "",
            ,
            ""
          ]
          data.unshift(header);
          data.push(footer);
          console.table(data);

          const workbook = XLSX.utils.book_new();

          // Convert the data into a worksheet
          const worksheet = XLSX.utils.aoa_to_sheet(data);

          // Append the worksheet to the workbook
          XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

          // Generate binary Excel file (as array) to save as XLSX
          const excelData = XLSX.write(workbook, { bookType: "xlsx", type: "array" });

          // Use FileSaver.js to save the file
          const blob = new Blob([excelData], { type: "application/octet-stream" });
          saveAs(blob, file_name);

        } else {
          // toast.error("Device not created.", { toastId: 2345353643 });
          console.log("error");
        }
      })
      .catch((error) => {
        {
          console.log("error", error);
        }
      });

    UserService.GetStandardData(
      "IEL_MOR_ARCHIVED",
      device_id, //device_id,
      "Load_Power",
      "daterange",
      "2023-12-30",
      "2024-12-31",
      0,
      "monthly"
    )
      .then((res) => {
        let powerDataFromDB = res.data.data.deviceData;
        console.table(powerDataFromDB);
        let myData;
        if (typeof powerDataFromDB != "undefined" && !res.data.data.error) {
          myData = Object.keys(powerDataFromDB).map((key) => {
            return [
              powerDataFromDB[key].date_time_A,
              powerDataFromDB[key]["Energy_Grid_SM_T_A"],
            ];
          });
        } else {
          myData = [];
        }
        if (myData.length > 0) {
          console.log(myData);
          let TableData = myData.map((x, i) => [
            new Date(x[0]).getFullYear(),
            new Date(x[0]).getMonth(),
            new Date(x[0]).getDate(),
            x[1],
            i == 0 ? 0 : (x[1] - myData[i - 1][1]).toFixed(2),
          ]);

          console.table(TableData);
          console.log(TableData[TableData.length][4],TableData[0][4]);


        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <>
      <div className="col-lg-12 col-sm-12">
        {" "}
        <Boxes
          virtual={0}
          StaticTxtValue1="Solar Power"
          StaticValue1={staticValue1}
          StaticUnit1="KW"
          StaticSize1={2}
          StaticTxtValue2="Daily Solar Energy"
          StaticValue2={staticValue2}
          StaticUnit2="KWH"
          StaticSize2={3}
          StaticTxtValue3="Monthly Solar Energy"
          StaticValue3={staticValue3}
          StaticUnit3="KWH"
          StaticSize3={3}
          // StaticTxtValue4="FREQUENCY"
          StaticTxtValue4="Frequency"
          StaticValue4={staticValue4}
          StaticUnit4="Hz"
          StaticSize4={2}
          StaticTxtValue5="POWER FACTOR"
          StaticValue5={staticValue5}
          StaticUnit5=""
          StaticSize5={1}
          StaticTxtValue6="TEMPERATURE"
          StaticValue6={staticValue6}
          StaticUnit6="℃"
          StaticSize6={1}
          StaticValue7={staticValue7}
          sendDataToParent={setPanelSiteDetail}
        ></Boxes>
      </div>
      <div className="col-lg-12 col-sm-12">
        <Card
          className="row"
          sx={{
            "border-radius": "1% ",

            boxShadow: 2,
            margin: "0 5px 10px",
            ":hover": {
              boxShadow: 5,
            },
            padding: "1%",
          }}
        >
          <div className="col-lg-2 col-sm-12">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Trend</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={Parameter}
                label="Format"
                // onChange={handleChangeParameter}
                onChange={(event) => {
                  setParameter(event.target.value);
                }}
              >
                {/* <MenuItem value={"power"}>Power</MenuItem>*/}
                {/* <MenuItem value={"energy"}>Energy</MenuItem> */}

                <MenuItem value={"power1"}>Power </MenuItem>
                {profileAccess.Power_load_menu == 1 ? (
                  <MenuItem value={"power2"}>Power Load</MenuItem>
                ) : null}
                {/* <MenuItem value={"power3"}>Power Solar</MenuItem> */}

                {/* <MenuItem value={"energy2"}>Energy Solar</MenuItem> */}
                {/* <MenuItem value={"energy3"}>Energy</MenuItem> */}
                <MenuItem value={"temperature"}>Temperature</MenuItem>
                {/* {profileAccess.Bill_menu == 1 ? (
                  <MenuItem value={"bill"}>Bill</MenuItem>
                ) : null} */}
                <MenuItem value={"alarm"}>Alarms</MenuItem>

                {profileAccess.site_detail == 1 ? (
                  <MenuItem value={"site"}>Site Detail</MenuItem>
                ) : null}
                {profileAccess.site_detail == 1 ? (
                  <MenuItem value={"newEnergy"}>Energy</MenuItem>
                ) : null}
              </Select>
            </FormControl>
          </div>
          <div className="col-lg-2 col-sm-12">
            {Parameter === "energy" ||
              Parameter === "energy2" ||
              Parameter === "newEnergy" ||
              Parameter === "energy3" ? (
              <>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Format</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={TimeFormat}
                    label="Format"
                    onChange={handleChangeFormat}
                  >
                    {/* {profileAccess.Hourly_menu == 1 ? (
                      <MenuItem value={"hourly"}>Hourly</MenuItem>
                    ) : null} */}
                    {/*  <MenuItem value={"hour"}>Hourly</MenuItem> */}
                    <MenuItem value={"daily"}>Daily</MenuItem>
                    <MenuItem value={"monthly"}>Monthly</MenuItem>
                  </Select>
                </FormControl>
              </>
            ) : (
              <>
                {Parameter === "newEnergy" && TimeFormat != "hour" ? (
                  <>
                    {" "}
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Year
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={EnergyYear}
                        label="Format"
                        onChange={handleChangeEnergyYear}
                      >
                        <MenuItem value={22}>2022</MenuItem>
                        <MenuItem value={23}>2023</MenuItem>
                        <MenuItem value={24}>2024</MenuItem>
                        <MenuItem value={25}>2025</MenuItem>
                      </Select>
                    </FormControl>
                  </>
                ) : null}
              </>
            )}
          </div>
          <div className="col-lg-2 col-sm-12">
            {Parameter != "control" &&
              Parameter != "bill" &&
              Parameter != "newEnergy" &&
              Parameter != "alarm" ? (
              <>
                <Typography> Real-Time</Typography>
                <Switch
                  checked={Rt}
                  onChange={() => setRt(!Rt)}
                  name="Real Time"
                // color="primary"
                />
              </>
            ) : (
              <>
                {Parameter === "newEnergy" ? (
                  <>
                    {" "}
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Year
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={EnergyYear}
                        label="Format"
                        onChange={handleChangeEnergyYear}
                      >
                        <MenuItem value={"22"}>2022</MenuItem>
                        <MenuItem value={"23"}>2023</MenuItem>
                        <MenuItem value={"24"}>2024</MenuItem>
                        <MenuItem value={"25"}>2025</MenuItem>
                      </Select>
                    </FormControl>
                  </>
                ) : null}
              </>
            )}
          </div>

          {Parameter != "control" &&
            Parameter != "bill" &&
            Parameter != "newEnergy" &&
            Parameter != "alarm" ? (
            <>
              <div
                className="col-lg-1 col-sm-12"
                style={{
                  padding: "1%",
                }}
              >
                <IconButton
                  color="primary"
                  aria-label="ArrowBackIosIcon"
                  onClick={handleBackIClick}
                >
                  <ArrowBackIosIcon />
                </IconButton>
              </div>
            </>
          ) : (
            <>
              <div className="col-lg-2 col-sm-12">
                {Parameter === "newEnergy" &&
                  (TimeFormat === "daily" || TimeFormat === "hour") ? (
                  <>
                    {" "}
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Month
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={EnergyMonth}
                        label="Format"
                        onChange={handleChangeEnergyMonth}
                      >
                        <MenuItem value={"1"}>January</MenuItem>
                        <MenuItem value={"2"}>February</MenuItem>
                        <MenuItem value={"3"}>March</MenuItem>
                        <MenuItem value={"4"}>April</MenuItem>
                        <MenuItem value={"5"}>May</MenuItem>
                        <MenuItem value={"6"}>June</MenuItem>
                        <MenuItem value={"7"}>July</MenuItem>
                        <MenuItem value={"8"}>August</MenuItem>
                        <MenuItem value={"9"}>September</MenuItem>
                        <MenuItem value={"10"}>October</MenuItem>
                        <MenuItem value={"11"}>November</MenuItem>
                        <MenuItem value={"12"}>December</MenuItem>
                      </Select>
                    </FormControl>
                  </>
                ) : null}
              </div>
              <div className="col-lg-2 col-sm-12">
                {Parameter === "newEnergy" && TimeFormat === "hour" ? (
                  <>
                    {" "}
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Day</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={EnergyDay}
                        label="Format"
                        onChange={handleChangeEnergyDay}
                      >
                        {[...Array(31)].map((_, index) => (
                          <MenuItem key={index + 1} value={index + 1}>
                            {index + 1}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </>
                ) : null}
              </div>
              <div className="col-lg-2 col-sm-12">
                {/* {Parameter === "newEnergy" ? ( */}
                {1 ? (
                  <>
                    {" "}
                    <Button variant="contained" size="large" color="success" onClick={Excel} >Excel</Button>
                  </>
                ) : null}
              </div>
            </>
          )}

          {Parameter != "control" &&
            Parameter != "bill" &&
            Parameter != "newEnergy" &&
            Parameter != "alarm" ? (
            <>
              <div
                className="col-lg-4 col-sm-12"
                style={{
                  padding: "1%",
                }}
              >
                <DateRangePicker onApply={handleApply}>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Select date range"
                    style={{
                      fontSize: 12,
                      border: "1px solid #46acad",
                      borderRadius: 25,
                    }}
                    value={FirstDataBack[0] + " " + LastDataFront[0]}
                  />
                </DateRangePicker>
              </div>
            </>
          ) : (
            <>
              {/* <div className="col-lg-4 col-sm-12">
                {Parameter === "newEnergy" ? (
                  <>
                    {" "}
                    {profileAccess.energy_type == 1 ? (
                      <FormControl>
                        <FormLabel id="demo-row-radio-buttons-group-label">
                          Energy Type
                        </FormLabel>
                        <RadioGroup
                          row
                          aria-labelledby="demo-controlled-radio-buttons-group"
                          name="controlled-radio-buttons-group"
                          value={EnergyType}
                          onChange={handleChangeEnergyType}
                        >
                          <FormControlLabel
                            value="1"
                            control={<Radio />}
                            label="Solar"
                          />
                          <FormControlLabel
                            value="2"
                            control={<Radio />}
                            label="Type 2"
                          />
                          <FormControlLabel
                            value="3"
                            control={<Radio />}
                            label="Type 3"
                          />
                          <FormControlLabel
                            value="4"
                            control={<Radio />}
                            label="Load"
                          />
                        </RadioGroup>
                      </FormControl>
                    ) : null}
                  </>
                ) : null}
              </div> */}
            </>
          )}

          <div
            className="col-lg-1 col-sm-12"
            style={{
              padding: "1%",
            }}
          >
            {Parameter != "control" &&
              Parameter != "bill" &&
              Parameter != "newEnergy" &&
              Parameter != "alarm" ? (
              <>
                <IconButton
                  color="primary"
                  aria-label="ArrowForwardIosIcon"
                  onClick={handleForwardClick}
                >
                  <ArrowForwardIosIcon />
                </IconButton>
              </>
            ) : (
              <></>
            )}
          </div>
        </Card>
      </div>
      <div className="col-lg-12 col-sm-12">
        {" "}
        {/* <h1>Chart</h1> */}
        {/* <div className="col-lg-12 col-sm-12"> */}
        {Pannel(Parameter, profileAccess)}
        {/* </div> */}
      </div>
      <div className="col-lg-12 col-sm-12" style={{ textAlign: "center" }}>
        {profileAccess.Bottom_boxes == 1 ? (
          <ToggleButtonGroup
            color="primary"
            value={processSelect}
            exclusive
            onChange={handleChangeProcessSelect}
            aria-label="Platform"
            style={{ textAlign: "center" }}
          >
            <ToggleButton value="solis">SOLAR</ToggleButton>
            {/* <ToggleButton value="iel-300">IEL-300 SOLAR</ToggleButton> */}
            <ToggleButton value="load">LOAD</ToggleButton>
            <ToggleButton value="new_bar">Additional info</ToggleButton>
          </ToggleButtonGroup>
        ) : null}
        <div style={{ textAlign: "center", margin: "2%" }}></div>
        {processSelect == "solis" ? (
          <Grid
            container
            spacing={3}
            direction="row"
            // justify="flex-start"
            // alignItems="flex-start"
            alignItems="center"
            justifyContent="center"
          >
            {phases == 1 ? null : (
              <>
                <PhaseBox
                  isGraphStatsLoading={0}
                  voltage={phase11.voltage}
                  current={phase11.current}
                  power={phase11.power}
                  color={"#F30202"}
                  type={1}
                />
                <PhaseBox
                  isGraphStatsLoading={0}
                  voltage={phase12.voltage}
                  current={phase12.current}
                  power={phase12.power}
                  color={"#0054F1"}
                  type={1}
                />
              </>
            )}

            <PhaseBox
              isGraphStatsLoading={0}
              voltage={phase13.voltage}
              current={phase13.current}
              power={phase13.power}
              color={"#FFD93D"}
              type={1}
            />
          </Grid>
        ) : null}
        {processSelect == "iel-300" ? (
          <Grid
            container
            spacing={3}
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
          >
            <PhaseBox
              isGraphStatsLoading={0}
              voltage={phase21.voltage}
              current={phase21.current}
              power={phase21.power}
              color={"#F30202"}
            />
          </Grid>

        ) : null}
        {processSelect == "load" ? (
          <Grid
            container
            spacing={3}
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            {phases == 1 ? (
              <>
                {" "}
                {/* <Grid spacing={1} item xs={12} sm={6} md={10}> */}
                <PhaseBox
                  isGraphStatsLoading={0}
                  voltage={phase31.voltage}
                  current={phase31.current}
                  power={phase31.power}
                  color={"#F30202"}
                  type={4}
                />{" "}
                {/* </Grid> */}
                <Grid spacing={1} item xs={1} sm={1} md={2}>
                  <PFBox
                    isGraphStatsLoading={0}
                    value={phase33.voltage}
                    StaticTxtValue5="Power Factor"
                    StaticValue5={staticValue5}
                    color={"#F30202"}
                  />
                </Grid>
              </>
            ) : (
              <>
                <Grid spacing={1} item xs={12} sm={6} md={10}>
                  <Grid
                    container
                    spacing={3}
                    direction="row"
                    justify="flex-start"
                    alignItems="flex-start"
                  >
                    <PhaseBox
                      isGraphStatsLoading={0}
                      voltage={phase31.voltage}
                      current={phase31.current}
                      power={phase31.power}
                      color={"#F30202"}
                    />{" "}
                    <PhaseBox
                      isGraphStatsLoading={0}
                      voltage={phase32.voltage}
                      current={phase32.current}
                      power={phase32.power}
                      color={"#0054F1"}
                    />
                    <PhaseBox
                      isGraphStatsLoading={0}
                      voltage={phase33.voltage}
                      current={phase33.current}
                      power={phase33.power}
                      color={"#FFD93D"}
                    />
                  </Grid>
                </Grid>

                <Grid spacing={1} item xs={1} sm={1} md={2}>
                  <PFBox
                    isGraphStatsLoading={0}
                    value={phase33.voltage}
                    StaticTxtValue5="Power Factor"
                    StaticValue5={staticValue5}
                    color={"#F30202"}
                  />
                </Grid>
              </>
            )}
          </Grid>
        ) : null}
        {processSelect == "new_bar" ? (
          <Grid
            container
            spacing={3}
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
          >
            <Grid spacing={1} item xs={3} sm={3} md={2}>
              <PFBox
                isGraphStatsLoading={0}
                StaticTxtValue5="Solar Voltage (dc)"
                StaticValue5={staticValue11}
                color={"#F30202"}
                Unit="V"
              />
            </Grid>
            <Grid spacing={1} item xs={3} sm={3} md={2}>
              <PFBox
                isGraphStatsLoading={0}
                StaticTxtValue5="Solar Power (dc)"
                StaticValue5={staticValue12}
                color={"#F30202"}
                Unit="Kw"
              />
            </Grid>
            <Grid spacing={1} item xs={3} sm={3} md={2}>
              <PFBox
                isGraphStatsLoading={0}
                StaticTxtValue5="Solar Totalizer"
                StaticValue5={staticValue13}
                color={"#F30202"}
                Unit="Kwh"
              />
            </Grid>
            <Grid spacing={1} item xs={3} sm={3} md={2}>
              <PFBox
                isGraphStatsLoading={0}
                StaticTxtValue5="Total CO2 Reduction"
                StaticValue5={staticValue14}
                color={"#F30202"}
                Unit="Ton"
              />
            </Grid>
            <Grid spacing={1} item xs={3} sm={3} md={2}>
              <PFBox
                isGraphStatsLoading={0}
                StaticTxtValue5="Load Power"
                StaticValue5={staticValue15}
                color={"#F30202"}
                Unit="Kw"
              />
            </Grid>
            <Grid spacing={1} item xs={3} sm={3} md={2}>
              <PFBox
                isGraphStatsLoading={0}
                StaticTxtValue5="Load Totalizer "
                StaticValue5={staticValue16}
                color={"#F30202"}
                Unit="Kwh"
              />
            </Grid>
          </Grid>
        ) : null}
      </div>
    </>
  );
}
