import UserService from "../../../services/user.service";
import React, { useState, useEffect, useRef } from "react";
import IconButton from "@mui/material/IconButton";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ReactApexChart from "react-apexcharts";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import Switch from "@mui/material/Switch";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import moment, { months } from "moment-timezone";
import { Card, CardContent, Grid } from "@mui/material";
import * as XLSX from 'xlsx';
import Button from "@mui/material/Button";
import { saveAs } from 'file-saver';
import { Modal } from "react-responsive-modal";

let months_name = [
  "",
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",]

const tzone = "Asia/Amman";

function formatDate(date) {
  date = new Date(date);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

export default function App(props) {
  const DeviceId = props.DeviceId;
  const userID = props.userID;
  const type = "line"; //props.type;
  const param = props.param;

  // console.log(props);
  const chartRef = useRef();
  const [isLoadingGraph, setisLoadingGraph] = useState(false);
  const [data, setData] = useState([]);
  const [data1, setData1] = useState([]);
  const [data2, setData2] = useState([]);
  const [data3, setData3] = useState([]);
  const [data4, setData4] = useState([]);
  const [data5, setData5] = useState([]);
  const [data6, setData6] = useState([]);
  const [DataAvailable, setDataAvailable] = useState(false);
  const [endDate, setendDate] = useState("");
  const [LastDataFront, setLastDataFront] = useState(0);
  const [FirstDataBack, setFirstDataBack] = useState(0);
  const [chartTitle, setChartTitle] = useState("chartTitle");
  const [childDevices, setChildDevices] = useState([]);
  const [Rt, setRt] = React.useState(true);
  const [format, setFormat] = React.useState("Monthly");
  const [parameter, setParameter] = React.useState(param);
  const [TimeFormat, setTtimeFormat] = React.useState("daily");
  const [EnergyYear, setEnergyYear] = React.useState("25");
  const [EnergyMonth, setEnergyMonth] = React.useState("1");
  const [EnergyDay, setEnergyDay] = React.useState("16");
  const [chartConfig, setChartConfig] = useState({});
  const [open, setOpen] = useState(false);

  const onCloseModal = () => setOpen(false);


  const handleChangeEnergyYear = (event, newAlignment) => {
    // console.log("EnergyYear", event.target.value);
    setEnergyYear(event.target.value);
  };

  const handleChangeEnergyMonth = (event, newAlignment) => {
    setEnergyMonth(event.target.value);
    // console.log("EnergyMonth", EnergyMonth);

  };
  const handleChangeEnergyDay = (event, newAlignment) => {
    setEnergyDay(event.target.value);
    // console.log("EnergyDay", EnergyDay);

  };

  function sumByMonthIndex(arr, type) {
    const result = arr.reduce((acc, curr) => {
      const { month_index, value, year_index, day_index } = curr;

      // If the month_index is not in the accumulator, initialize it
      if (type == "month") {
        if (!acc[month_index]) {
          acc[month_index] = { value: 0, year_index, day_index };
        }

        // Sum the value for the given month_index
        acc[month_index].value += value;
      }

      if (type == "day") {
        if (!acc[day_index]) {
          acc[day_index] = { value: 0, year_index, month_index, day_index };
        }

        // Sum the value for the given day_index
        acc[day_index].value += value;
      }

      return acc;
    }, {});

    // Convert the result into the desired date and value format
    // console.log(result)
    if (type == "month")
      return Object.keys(result).map((month) => {
        const { value, year_index, day_index } = result[month];
        const year = year_index + 2000;
        const day = type == "month" ? 15 : day_index;
        const date = new Date(`${year}-${String(month).padStart(2, '0')}-${day}`); // Format date as YYYY-MM-15
        return [date, value];
      });

    if (type == "day")
      return Object.keys(result).map((month) => {
        const { value, year_index, day_index, month_index } = result[month];
        const year = year_index + 2000;
        const day = type == "month" ? 15 : day_index;
        const date = new Date(`${year}-${String(month_index).padStart(2, '0')}-${day}`); // Format date as YYYY-MM-15
        return [date, value];
      });

  }
  function sumEnergyByDate(data) {
    data = Object.values(data);
    // console.log("xx", data);

    // if(data.length == 1) return {...data,diff:data.Accumulated_Energy_Solis}
    // Step 1: Create a map to group objects by date_time_A
    const groupedByDate = data.reduce((acc, obj) => {
      if (!acc[obj.date_time_A]) {
        acc[obj.date_time_A] = [];
      }
      acc[obj.date_time_A].push(obj);
      return acc;
    }, {});

    // console.log("groupedByDate", groupedByDate);
    // Step 2: Collect all unique id222 values
    const allIds = [...new Set(data.map((obj) => obj.id222))];

    // Step 3: Get an array of sorted dates
    const sortedDates = Object.keys(groupedByDate).sort(
      (a, b) => new Date(a) - new Date(b)
    );

    // Step 4: Ensure each group has all id222 values, look for the most recent value for missing id222
    const ensureAllIdsPresent = (groupedByDate, allIds, sortedDates) => {
      for (let i = 1; i < sortedDates.length; i++) {
        const currentDate = sortedDates[i];
        const previousDate = sortedDates[i - 1];
        const currentGroup = groupedByDate[currentDate];
        const previousGroup = groupedByDate[previousDate];

        const existingIds = currentGroup.map((obj) => obj.id222);
        const missingIds = allIds.filter((id) => !existingIds.includes(id));

        missingIds.forEach((missingId) => {
          // Find the most recent value of the missing id222 from the previous group
          const previousObject = previousGroup.find(
            (obj) => obj.id222 === missingId
          );
          if (previousObject) {
            const newObject = {
              id222: missingId,
              time: currentGroup[0].time,
              date_time_A: currentDate,
              Accumulated_Energy_Solis: previousObject.Accumulated_Energy_Solis,
            };
            currentGroup.push(newObject);
          }
        });
      }
    };

    ensureAllIdsPresent(groupedByDate, allIds, sortedDates);

    // Step 2: Sum the Accumulated_Energy_Solis for each date
    const summedData = Object.keys(groupedByDate).map((date) => {
      const totalEnergy = groupedByDate[date].reduce(
        (sum, item) => sum + item.Accumulated_Energy_Solis,
        0
      );
      return { date_time_A: date, Accumulated_Energy_Solis: totalEnergy };
    });

    // Step 3: Calculate the difference from the previous day
    summedData.sort(
      (a, b) => new Date(a.date_time_A) - new Date(b.date_time_A)
    ); // Sort dates chronologically

    let previousTotal = 0;
    const result = summedData.map((entry, index) => {
      const difference = entry.Accumulated_Energy_Solis - previousTotal;
      previousTotal = entry.Accumulated_Energy_Solis;
      return {
        date_time_A: entry.date_time_A,
        Accumulated_Energy_Solis: entry.Accumulated_Energy_Solis,
        diff: index === 0 ? entry.Accumulated_Energy_Solis : difference, // No difference for the first day
      };
    });

    // console.log("xxr", result);
    if (result.length === 1) return result;
    else return result.slice(1);
    // return result.slice(1);
  }
  function getChartData(powerDataFromDB, field) {
    let dNotNull = 0;
    return Object.values(powerDataFromDB).map((x) => {
      let t, d;
      t = x.date_time_A;
      d = x[field];
      if (d != null && d != 0 && d != -127) dNotNull = d;
      d = d === null ? dNotNull : d;
      d = d === -127 ? dNotNull : d;
      d = d === 0 ? dNotNull : d;
      return [t, d];
    });
  }

  const handleChangeFormat = (event) => {
    setTtimeFormat(event.target.value);
    // console.log(event.target.value);
    // handleApply();
  };

  function getData(DeviceId, startTime, endTime) {
    let fields;
    if (TimeFormat == "monthly") {
      const diffInMonths = moment(endTime).diff(moment(startTime), "months");

      // console.log(
      //   `The difference between the two dates is ${diffInMonths} months.`
      // );
      if (diffInMonths < 4)
        startTime = moment(startTime)
          .subtract("2", "months")
          .format("YYYY-MM-DD HH:mm:ss");
    }
    fields = ["diff"];

    setisLoadingGraph(true);
    let localUnit;
    // console.log(" get data power");
    localUnit = "KW";
    UserService.getVIrtualDeviceMoroccoData({
      id: DeviceId,
      type: "Energy", // process_process , power , energy
      format: TimeFormat,
      startDate: startTime, //`2024-05-15 00:00:00`,
      endDate: endTime, //`2024-05-16 00:00:00`,
    })
      .then((res) => {
        let powerDataFromDB = res.data.data.deviceData;
        // console.log("GEt Data!!!!!!!!!", powerDataFromDB);
        let myData;
        let myData1;
        let myData2;
        let myData3;
        let myData4;
        let myData5;
        let myData6;
        if (typeof powerDataFromDB != "undefined" && !res.data.data.error) {
          myData = getChartData(sumEnergyByDate(powerDataFromDB), fields[0]);
          myData1 = getChartData(sumEnergyByDate(powerDataFromDB), fields[1]);
          myData2 = getChartData(sumEnergyByDate(powerDataFromDB), fields[2]);
          myData3 = getChartData(sumEnergyByDate(powerDataFromDB), fields[3]);
          myData4 = getChartData(sumEnergyByDate(powerDataFromDB), fields[4]);
          myData4 = getChartData(sumEnergyByDate(powerDataFromDB), fields[5]);
          myData4 = getChartData(sumEnergyByDate(powerDataFromDB), fields[6]);
          // console.log(myData);
        } else {
          myData = [];
        }
        if (myData.length > 0) {
          // console.log(myData);
          setLastDataFront(myData.at(-1));
          setFirstDataBack(myData.at(0));
          setendDate(endTime);

          setData(myData);
          setData1(myData1);
          setData2(myData2);
          setData3(myData3);
          setData4(myData4);

          setChartConfig({
            chart: {
              id: "total-power-chart",
              // type: "line",
              // stacked: true,
              height: 500,
              foreColor: "#000000",
              zoom: {
                type: "x",
                enabled: true,
                autoScaleYaxis: true,
              },
              toolbar: {
                autoSelected: "zoom",
              },
            },
            tooltip: {
              enabled: true,
              color: "#000000",
              style: {
                fontSize: "16px",
                // fontFamily: undefined
              },
              // shared: true,
              x: {
                format: "dd MMM yyyy hh:mm TT",
              },
              y: [
                {
                  formatter: function (y) {
                    if (typeof y !== "undefined") {
                      return y.toFixed(2) + " " + localUnit;
                    }
                    return y;
                  },
                },
                {
                  formatter: function (y) {
                    if (typeof y !== "undefined") {
                      return y.toFixed(2) + " " + localUnit;
                    }
                    return y;
                  },
                },
                {
                  formatter: function (y) {
                    if (typeof y !== "undefined") {
                      return y.toFixed(2) + " " + localUnit;
                    }
                    return y;
                  },
                },
                {
                  formatter: function (y) {
                    if (typeof y !== "undefined") {
                      return y.toFixed(2) + " " + localUnit;
                    }
                    return y;
                  },
                },
              ],
            },
            dataLabels: {
              enabled: false,
            },
            markers: {
              size: 0,
            },
            xaxis: {
              type: "datetime",
              tickAmount: myData.length - 1,
              tooltip: {
                enabled: false,
              },
              labels: {
                rotate: -90,
                rotateAlways: true,
                minHeight: 100,
                maxHeight: 160,
                show: true,
                align: "left",
                // trim: true,
                formatter: function (val, timestamp) {
                  if (TimeFormat === "hourly")
                    return moment(timestamp).format("HH:00");
                  if (TimeFormat === "daily")
                    return moment(timestamp).format("DD MMM");
                  if (TimeFormat === "monthly")
                    return moment(timestamp).format("MMM YYYY");
                },
                style: {
                  colors: ["#000000"],
                  fontSize: "15px",
                  // fontFamily: 'Helvetica, Arial, sans-serif',
                  fontWeight: 600,
                },
              },
            },
            stroke: {
              width: 2,
            },
            colors: ["#64006C", "#0054F1", "#00FF00", "#FFD93D"],
            yaxis: {
              opposite: false,
              formatter: (value) => {
                return value;
              },
              min: 0,
              decimalsInFloat: 2,
              forceNiceScale: true,
              axisBorder: {
                show: true,
                color: "#78909C",
                offsetX: 0,
                offsetY: 0,
              },
              axisTicks: {
                show: true,
                borderType: "solid",
                color: "#78909C",
                width: 6,
                offsetX: 0,
                offsetY: 0,
              },
              labels: {
                show: true,
                align: "right",
                minWidth: 0,
                maxWidth: 160,
                style: {
                  colors: [],
                  fontSize: "15px",
                  // fontFamily: 'Helvetica, Arial, sans-serif',
                  fontWeight: 600,
                },
                offsetX: 0,
                offsetY: 0,
                rotate: 0,
                // formatter: (value) => { return val },
              },
              crosshairs: {
                show: true,
                position: "back",
                stroke: {
                  color: "#b6b6b6",
                  width: 0.6,
                  dashArray: [0, 1, 2],
                },
              },
              tooltip: {
                enabled: false,
                offsetX: 0,
              },
              title: {
                text: "[" + localUnit + "]",
                rotate: 0,
                offsetX: 40,
                offsetY: -170,
                style: {
                  color: undefined,
                  fontSize: "15px",
                  // fontFamily: 'Helvetica, Arial, sans-serif',
                  fontWeight: 600,
                  // cssClass: 'apexcharts-yaxis-title',
                },
              },
            },
            legend: {
              showForSingleSeries: true,
              offsetX: 7,
              //  offsetY:5,
              position: "top",
              fontSize: "18px",
              //  width:1
              //  fontWeight: 800,
              markers: {
                width: 12,
                height: 12,
                strokeWidth: 2,
                strokeColor: "#fff",
                fillColors: undefined,
                radius: 12,
                offsetX: -5,
                // offsetY: 5
              },
            },
          });

        } else {
          setDataAvailable(false);
        }
        setisLoadingGraph(false);
      })
      .catch((err) => {
        setisLoadingGraph(false);
        setDataAvailable(false);
      });
  }


  function getDataEnergy(DeviceId, year_index, month_index, day_index) {
    // console.log("request data for :", DeviceId, year_index, month_index, day_index);
    let startTime = moment().format("YYYY-MM-DD HH:mm:ss");
    let endTime = moment().format("YYYY-MM-DD 23:59:59");
    // let fields;
    // if (TimeFormat == "monthly") {
    //   const diffInMonths = moment(endTime).diff(moment(startTime), "months");

    //   console.log(
    //     `The difference between the two dates is ${diffInMonths} months.`
    //   );
    //   if (diffInMonths < 4)
    //     startTime = moment(startTime)
    //       .subtract("2", "months")
    //       .format("YYYY-MM-DD HH:mm:ss");
    // }
    // fields = ["diff"];

    setisLoadingGraph(true);
    // let localUnit;
    // console.log(" get data power");
    let localUnit = "KW";
    let type;
    if (TimeFormat == "daily") type = "day";
    else if (TimeFormat == "hour") type = "hour";
    else type = "month";
    let payload = {
      "deviceId": DeviceId,
      type,
      "year": EnergyYear,
      "month": EnergyMonth,
      "day": EnergyDay,
      "TimeFormat": "daily"
    };
    UserService.PostEnergyDataVir(payload)
      .then((res) => {
        let DataFromDB = res.data.data.deviceData;
        // console.table(DataFromDB);
        // console.table("GEt Data!!!!!!!!!", DataFromDB);
        let en_1 = Object.values(DataFromDB).filter(x => x.energy_type == '1');
        let en_4 = Object.values(DataFromDB).filter(x => x.energy_type == '4');
        // console.table(en_1);
        // console.table(en_4);

        en_1 = sumByMonthIndex(en_1, type);
        en_4 = sumByMonthIndex(en_4, type);

        // console.table(en_1);
        // console.table(en_4);

        setData(en_1);
        setData1(en_4);
        setChartConfig({
          chart: {
            id: "total-power-chart",
            // type: "line",
            // stacked: true,
            height: 500,
            foreColor: "#000000",
            zoom: {
              type: "x",
              enabled: true,
              autoScaleYaxis: true,
            },
            toolbar: {
              autoSelected: "zoom",
            },
          },
          tooltip: {
            enabled: true,
            color: "#000000",
            style: {
              fontSize: "16px",
              // fontFamily: undefined
            },
            // shared: true,
            x: {
              format: "dd MMM yyyy hh:mm TT",
            },
            y: [
              {
                formatter: function (y) {
                  if (typeof y !== "undefined") {
                    return y.toFixed(2) + " " + localUnit;
                  }
                  return y;
                },
              },
              {
                formatter: function (y) {
                  if (typeof y !== "undefined") {
                    return y.toFixed(2) + " " + localUnit;
                  }
                  return y;
                },
              },
              {
                formatter: function (y) {
                  if (typeof y !== "undefined") {
                    return y.toFixed(2) + " " + localUnit;
                  }
                  return y;
                },
              },
              {
                formatter: function (y) {
                  if (typeof y !== "undefined") {
                    return y.toFixed(2) + " " + localUnit;
                  }
                  return y;
                },
              },
            ],
          },
          dataLabels: {
            enabled: false,
          },
          markers: {
            size: 0,
          },
          xaxis: {
            type: "datetime",
            tickAmount: en_1.length - 1,
            tooltip: {
              enabled: false,
            },
            labels: {
              rotate: -90,
              rotateAlways: true,
              minHeight: 100,
              maxHeight: 160,
              show: true,
              align: "left",
              // trim: true,
              formatter: function (val, timestamp) {
                if (TimeFormat === "hourly")
                  return moment(timestamp).format("HH:00");
                if (TimeFormat === "daily")
                  return moment(timestamp).format("DD MMM");
                if (TimeFormat === "monthly")
                  return moment(timestamp).format("MMM YYYY");
              },
              style: {
                colors: ["#000000"],
                fontSize: "15px",
                // fontFamily: 'Helvetica, Arial, sans-serif',
                fontWeight: 600,
              },
            },
          },
          stroke: {
            width: 2,
          },
          colors: ["#64006C", "#F30202", "#0054F1", "#FFD93D"],

          yaxis: {
            opposite: false,
            formatter: (value) => {
              return value;
            },
            min: 0,
            decimalsInFloat: 2,
            forceNiceScale: true,
            axisBorder: {
              show: true,
              color: "#78909C",
              offsetX: 0,
              offsetY: 0,
            },
            axisTicks: {
              show: true,
              borderType: "solid",
              color: "#78909C",
              width: 6,
              offsetX: 0,
              offsetY: 0,
            },
            labels: {
              show: true,
              align: "right",
              minWidth: 0,
              maxWidth: 160,
              style: {
                colors: [],
                fontSize: "15px",
                // fontFamily: 'Helvetica, Arial, sans-serif',
                fontWeight: 600,
              },
              offsetX: 0,
              offsetY: 0,
              rotate: 0,
              // formatter: (value) => { return val },
            },
            crosshairs: {
              show: true,
              position: "back",
              stroke: {
                color: "#b6b6b6",
                width: 0.6,
                dashArray: [0, 1, 2],
              },
            },
            tooltip: {
              enabled: false,
              offsetX: 0,
            },
            title: {
              text: "[" + localUnit + "]",
              rotate: 0,
              offsetX: 40,
              offsetY: -170,
              style: {
                color: undefined,
                fontSize: "15px",
                // fontFamily: 'Helvetica, Arial, sans-serif',
                fontWeight: 600,
                // cssClass: 'apexcharts-yaxis-title',
              },
            },
          },
          legend: {
            showForSingleSeries: true,
            offsetX: 7,
            //  offsetY:5,
            position: "top",
            fontSize: "18px",
            //  width:1
            //  fontWeight: 800,
            markers: {
              width: 12,
              height: 12,
              strokeWidth: 2,
              strokeColor: "#fff",
              fillColors: undefined,
              radius: 12,
              offsetX: -5,
              // offsetY: 5
            },
          },
        });
      })
      .catch((err) => {
        setisLoadingGraph(false);
        setDataAvailable(false);
      });
  }

  const handleApply = (event, picker) => {
    setRt(false);

    // console.log("handle Apply");
    let startDateObj = picker.startDate._d;
    let endDateObj = picker.endDate._d;
    let startTime = moment(startDateObj).format("YYYY-MM-DD HH:mm:ss");
    let endTime = moment(endDateObj).format("YYYY-MM-DD 23:59:59");
    // console.log("startDateObjDate", startTime);
    // console.log("endDateObjDate", endTime);

    getData(DeviceId, startTime, endTime);
  };


  useEffect(() => {
    // Get the first day of the current month
    let startTime = moment().startOf('month').format("YYYY-MM-DD HH:mm:ss");
    // Get the last day of the current month
    let endTime = moment().endOf('month').format("YYYY-MM-DD HH:mm:ss");

    // console.log("Start Time:", startTime);
    // console.log("End Time:", endTime);
    // //current months
    // console.log("loading power compo");
    // getData(DeviceId, startTime, endTime);
  }, [DeviceId, TimeFormat]);


  useEffect(() => {

    // getData(DeviceId, startTime, endTime);
    //  console.log("update in params");
    //  console.log("EnergyDay", EnergyDay);
    //  console.log("EnergyMonth", EnergyMonth);
    //  console.log("EnergyYear", EnergyYear);
    getDataEnergy(DeviceId, EnergyYear, EnergyMonth, EnergyDay,);
  }, [DeviceId, TimeFormat, EnergyDay, EnergyMonth, EnergyYear]);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     console.log("Refsh 30 second");
  //     let endTime = moment().format("YYYY-MM-DD HH:mm:ss");
  //     let startTime = moment()
  //       .subtract(5, "days")
  //       .format("YYYY-MM-DD HH:mm:ss");
  //     if (Rt) getData(DeviceId, startTime, endTime);
  //   }, 30 * 1000);
  //   return () => clearInterval(interval);
  // }, [DeviceId, Rt, TimeFormat]);

  function renameFieldInArray(arr, oldFieldName, newFieldName) {
    return arr.map(obj => {
      // Create a new object with the rest of the fields
      const newObj = { ...obj };
      // If the old field exists, rename it
      if (oldFieldName in newObj) {
        newObj[newFieldName] = newObj[oldFieldName];
        delete newObj[oldFieldName]; // Remove the old field
      }
      return newObj;
    });
  }


  function mergeArraysByMonthIndex(arr1, arr2) {
    const mergedResult = [];

    // Combine both arrays
    const combined = [...arr1, ...arr2];

    // Create a map to store objects by month_index and device_id
    const monthIndexMap = {};

    combined.forEach((obj) => {
      const { month_index, device_id, ...fields } = obj;

      // Use a combined key of month_index and device_id
      const key = `${month_index}_${device_id}`;

      // Check if this key already exists in the map
      if (monthIndexMap[key] && monthIndexMap[key].device_id === device_id) {
        // If exists and device_id matches, merge fields
        Object.keys(fields).forEach((fieldKey) => {
          monthIndexMap[key][fieldKey] = fields[fieldKey];
        });
      } else if (!monthIndexMap[key]) {
        // If not, add a new entry to the map
        monthIndexMap[key] = { month_index, device_id, ...fields };
      }

      // Add 'solis' and 'load' with default value null if not present
      monthIndexMap[key].solis = monthIndexMap[key].solis ?? null;
      monthIndexMap[key].load = monthIndexMap[key].load ?? null;
    });

    // After combining, ensure all objects have consistent fields
    Object.values(monthIndexMap).forEach((obj) => {
      const allFields = new Set([
        ...Object.keys(arr1[0] || {}),
        ...Object.keys(arr2[0] || {}),
        'solis', 'load'  // Ensure 'solis' and 'load' fields are present
      ]);

      allFields.forEach((field) => {
        if (!obj.hasOwnProperty(field)) {
          obj[field] = null;
        }
      });

      mergedResult.push(obj);
    });

    return mergedResult;
  }


  function mergeArraysByDayIndex_(arr1, arr2) {
    const mergedResult = [];

    // Combine both arrays
    const combined = [...arr1, ...arr2];

    // Create a map to store objects by day_index
    const dayIndexMap = {};

    combined.forEach((obj) => {
      const { day_index, ...fields } = obj;

      // Check if day_index already exists in the map
      if (dayIndexMap[day_index]) {
        // If exists, merge fields (add missing fields with null values)
        Object.keys(fields).forEach((key) => {
          dayIndexMap[day_index][key] = fields[key];
        });
      } else {
        // If not, add a new entry to the map
        dayIndexMap[day_index] = { day_index, ...fields };
      }

      // Add 'solis' and 'load' with default value null if not present
      dayIndexMap[day_index].solis = dayIndexMap[day_index].solis ?? null;
      dayIndexMap[day_index].load = dayIndexMap[day_index].load ?? null;
    });

    // After combining, ensure all objects have consistent fields
    Object.values(dayIndexMap).forEach((obj) => {
      // Merge missing fields from both arrays, set missing ones to null
      const allFields = new Set([
        ...Object.keys(arr1[0] || {}),
        ...Object.keys(arr2[0] || {}),
        'solis', 'load'  // Ensure 'solis' and 'load' fields are present
      ]);

      allFields.forEach((field) => {
        if (!obj.hasOwnProperty(field)) {
          obj[field] = null;
        }
      });

      mergedResult.push(obj);
    });

    return mergedResult;
  }

  function mergeArraysByDayIndex(arr1, arr2) {
    const mergedResult = [];

    // Combine both arrays
    const combined = [...arr1, ...arr2];

    // Create a map to store objects by day_index and device_id
    const dayIndexMap = {};

    combined.forEach((obj) => {
      const { day_index, device_id, ...fields } = obj;

      // Use a combined key of day_index and device_id
      const key = `${day_index}_${device_id}`;

      // Check if this key already exists in the map
      if (dayIndexMap[key] && dayIndexMap[key].device_id === device_id) {
        // If exists and device_id matches, merge fields
        Object.keys(fields).forEach((fieldKey) => {
          dayIndexMap[key][fieldKey] = fields[fieldKey];
        });
      } else if (!dayIndexMap[key]) {
        // If not, add a new entry to the map
        dayIndexMap[key] = { day_index, device_id, ...fields };
      }

      // Add 'solis' and 'load' with default value null if not present
      dayIndexMap[key].solis = dayIndexMap[key].solis ?? null;
      dayIndexMap[key].load = dayIndexMap[key].load ?? null;
    });

    // After combining, ensure all objects have consistent fields
    Object.values(dayIndexMap).forEach((obj) => {
      const allFields = new Set([
        ...Object.keys(arr1[0] || {}),
        ...Object.keys(arr2[0] || {}),
        'solis', 'load'  // Ensure 'solis' and 'load' fields are present
      ]);

      allFields.forEach((field) => {
        if (!obj.hasOwnProperty(field)) {
          obj[field] = null;
        }
      });

      mergedResult.push(obj);
    });

    return mergedResult;
  }

  function sumIndexFive(data) {
    let sum = 0;
    for (const innerArray of data) {
      // Check if innerArray is null or if the value at index 5 is invalid
      if (!innerArray || innerArray[5] === null || innerArray[5] === undefined) {
        return 0; // Immediately return 0 if any inner array is null or index 5 is invalid
      }
      sum += innerArray[5] || 0; // Add the value at index 5, or 0 if it’s falsy
    }
    return sum; // Return the sum if all elements are valid
  }

  function getCurrentDateTime() {
    const now = new Date();

    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');

    return `${year}_${month}_${day}_${hours}_${minutes}_${seconds}`;
  }
  const OpenModal = () => {
    setOpen(true);

  }
  const Excel = () => {
    console.log("excel Generation");
    console.log("get process");
    let type;
    if (TimeFormat == "daily") type = "day";
    else if (TimeFormat == "hour") type = "hour";
    else type = "month";
    // const TimeFormat = TimeFormat;
    let file_name = `${DeviceId}_${type}_${getCurrentDateTime()}.xlsx`
    let en_1;
    let en_4;
    const payload = {
      deviceId: DeviceId,
      type,
      year: EnergyYear,
      month: EnergyMonth,
      day: EnergyDay,
      TimeFormat: TimeFormat,
      isVirtual: 1
    };
    UserService.PostEnergyDataTable(payload)
      .then((resData) => {
        if (resData.data.type === "success") {
          let res = Object.values(resData.data.data.deviceData);
         // console.table(res);

          const daysInMonth = moment(`${EnergyYear + 2000}-${EnergyMonth}`, "YYYY-MM").daysInMonth() + 1;

          if (type == "month") {
            en_1 = Object.values(res).filter(x => x.energy_type == '1' && x.day_index < daysInMonth && x.month_index == EnergyMonth);
            en_4 = Object.values(res).filter(x => x.energy_type == '4' && x.day_index < daysInMonth && x.month_index == EnergyMonth);
          }

          
          if (type == "day") {
            en_1 = Object.values(res).filter(x => x.energy_type == '1' && x.day_index < daysInMonth && x.month_index == EnergyMonth  && x.day_index == EnergyDay);
            en_4 = Object.values(res).filter(x => x.energy_type == '4' && x.day_index < daysInMonth && x.month_index == EnergyMonth  && x.day_index == EnergyDay);
          }

          let res1 = Object.values(renameFieldInArray(en_1, "value", "solis"));
          console.log("enr 1", res1);
          console.table(res1);

          let res2 = Object.values(renameFieldInArray(en_4, "value", "load"));
          console.log("enr 4", res2);
          console.table(res2);

          let mergedResult;
          if (type == "month") { mergedResult = mergeArraysByMonthIndex(res1, res2); }
          if (type == "day") { mergedResult = mergeArraysByDayIndex(res1, res2); }

          // console.table(mergedResult);
          let data = []; 
          data = (mergedResult.map(x => [
            x.site_id,
            x.state,
            type == "day" ? x.day_index + " " + months_name[x.month_index] + " " + (x.year_index + 2000) : months_name[x.month_index] + " " + (x.year_index + 2000),
            "Outdoor",
            x.capacity_per_panel,
            x.load,
            x.solis,
            (x.solis * 0.0006956 * 10000) / 1000,
            (x.solis !== null && x.load !== null && x.load !== 0) ? ((x.solis / x.load) * 100).toFixed(2) + "%" : null,
            x.deployment_date

          ]))
          console.log("mergedResult");
          console.table(mergedResult);


          console.log("data");
          console.table(data);


          const header = ["Site", "Région", "Date", "Site Type", "Site Capacity (kWp)", "AC Supply  (kWh),", "Solar Supply  (kWh)", "CO2 (ton)", "RER (%)", "Date Intégration"];
          const footer = [
            "Total",
            "",
            "",
            "",
            "",
           sumIndexFive(data),
            data.reduce((sum, innerArray) => {
              return sum + (innerArray[6] || 0);
            }, 0).toFixed(2),
            "",
            ,
            ""
          ]

          data = data.map(innerArray => {
            if (innerArray[5] === null || innerArray[5] === undefined) {
              innerArray[5] = 0;
            }
            return innerArray;
          });

          data.unshift(header);
          data.push(footer);
          console.table(data);

          const workbook = XLSX.utils.book_new();

          // Convert the data into a worksheet
          const worksheet = XLSX.utils.aoa_to_sheet(data);

          // Append the worksheet to the workbook
          XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

          // Generate binary Excel file (as array) to save as XLSX
          const excelData = XLSX.write(workbook, { bookType: "xlsx", type: "array" });

          // Use FileSaver.js to save the file
          const blob = new Blob([excelData], { type: "application/octet-stream" });
          saveAs(blob, file_name);
          setOpen(false);

        } else {
          // toast.error("Device not created.", { toastId: 2345353643 });
          console.log("error");
        }
      })
      .catch((error) => {
        {
          console.log("error", error);
        }
      });

  }


  return (
    <>
      <div className="col-lg-12 col-sm-12">
        <Card
          className="row"
          sx={{
            "border-radius": "1% ",

            boxShadow: 2,
            margin: "",
            ":hover": {
              boxShadow: 5,
            },
            padding: "1%",
          }}
        >
          <div className="col-lg-2 col-sm-12">
            <Typography> Real-Time</Typography>
          </div>
          <div className="col-lg-2 col-sm-12">
            {" "}
            <Switch
              checked={Rt}
              onChange={() => setRt(!Rt)}
              name="Real Time"
            // color="primary"
            />
          </div>
          <div className="col-lg-2 col-sm-12">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Format</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={TimeFormat}
                label="Format"
                onChange={handleChangeFormat}
              >
                <MenuItem value={"daily"}>Daily</MenuItem>
                <MenuItem value={"monthly"}>Monthly</MenuItem>
              </Select>
            </FormControl>
          </div>

          <div className="col-lg-2 col-sm-12">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Year
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={EnergyYear}
                label="Format"
                onChange={handleChangeEnergyYear}
              >
                <MenuItem value={"22"}>2022</MenuItem>
                <MenuItem value={"23"}>2023</MenuItem>
                <MenuItem value={"24"}>2024</MenuItem>
                <MenuItem value={"25"}>2025</MenuItem>
              </Select>
            </FormControl>


          </div>

          <div className="col-lg-2 col-sm-12">
            {(TimeFormat === "daily") ?
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Month
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={EnergyMonth}
                  label="Format"
                  onChange={handleChangeEnergyMonth}
                >
                  <MenuItem value={"1"}>January</MenuItem>
                  <MenuItem value={"2"}>February</MenuItem>
                  <MenuItem value={"3"}>March</MenuItem>
                  <MenuItem value={"4"}>April</MenuItem>
                  <MenuItem value={"5"}>May</MenuItem>
                  <MenuItem value={"6"}>June</MenuItem>
                  <MenuItem value={"7"}>July</MenuItem>
                  <MenuItem value={"8"}>August</MenuItem>
                  <MenuItem value={"9"}>September</MenuItem>
                  <MenuItem value={"10"}>October</MenuItem>
                  <MenuItem value={"11"}>November</MenuItem>
                  <MenuItem value={"12"}>December</MenuItem>
                </Select>
              </FormControl>
              : (<></>)}
          </div>
          <div className="col-lg-2 col-sm-12">
            {/* {Parameter === "newEnergy" ? ( */}
            {1 ? (
              <>
                {" "}
                <Button variant="contained" size="large" color="success" onClick={OpenModal} >Download Data</Button>
              </>
            ) : null}
          </div>

          {/* <div className="col-lg-2 col-sm-12">
          {(TimeFormat === "daily") ? (<></>):(<></>)}
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Day</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={EnergyDay}
                label="Format"
                onChange={handleChangeEnergyDay}
              >
                {[...Array(31)].map((_, index) => (
                  <MenuItem key={index + 1} value={index + 1}>
                    {index + 1}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

          </div> */}


          <div className="col-lg-12 col-sm-12"><br></br></div>

          <div className="col-lg-12 col-sm-12"><br></br></div>
          <div className="col-lg-12 col-sm-12">
            <ReactApexChart
              height={450}
              ref={chartRef}
              options={chartConfig}
              series={[
                { name: "Load energy", data: data1 },
                { name: "Solar energy", data: data },
              ]}
              type="bar"
            />
          </div>

          <Modal open={open} onClose={onCloseModal} center >
            <div className="row">
              <div className="col-lg-12 col-sm-12">
                <h4 style={{ textAlign: "center" }}>Download Data</h4>
              </div>
              <div className="col-lg-12 col-sm-12" style={{ margin: "10px" }}>
              </div>

              <div className="col-lg-12 col-sm-12" style={{ margin: "10px" }}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Format</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={TimeFormat}
                    label="Format"
                    onChange={handleChangeFormat}
                  >
                    <MenuItem value={"daily"}>Daily</MenuItem>
                    <MenuItem value={"monthly"}>Monthly</MenuItem>
                  </Select>
                </FormControl>
              </div>

              <div className="col-lg-12 col-sm-12" style={{ margin: "10px" }}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Year
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={EnergyYear}
                    label="Format"
                    onChange={handleChangeEnergyYear}
                  >
                    <MenuItem value={"22"}>2022</MenuItem>
                    <MenuItem value={"23"}>2023</MenuItem>
                    <MenuItem value={"24"}>2024</MenuItem>
                    <MenuItem value={"25"}>2025</MenuItem>
                  </Select>
                </FormControl>


              </div>

              <div className="col-lg-12 col-sm-12" style={{ margin: "10px" }}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Month
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={EnergyMonth}
                    label="Format"
                    onChange={handleChangeEnergyMonth}
                  >
                    <MenuItem value={"1"}>January</MenuItem>
                    <MenuItem value={"2"}>February</MenuItem>
                    <MenuItem value={"3"}>March</MenuItem>
                    <MenuItem value={"4"}>April</MenuItem>
                    <MenuItem value={"5"}>May</MenuItem>
                    <MenuItem value={"6"}>June</MenuItem>
                    <MenuItem value={"7"}>July</MenuItem>
                    <MenuItem value={"8"}>August</MenuItem>
                    <MenuItem value={"9"}>September</MenuItem>
                    <MenuItem value={"10"}>October</MenuItem>
                    <MenuItem value={"11"}>November</MenuItem>
                    <MenuItem value={"12"}>December</MenuItem>
                  </Select>
                </FormControl>

              </div>

              <div className="col-lg-12 col-sm-12" style={{ margin: "10px" }}>
                {(TimeFormat === "daily") ?
                  <>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Day</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={EnergyDay}
                        label="Format"
                        onChange={handleChangeEnergyDay}
                      >
                        {[...Array(31)].map((_, index) => (
                          <MenuItem key={index + 1} value={index + 1}>
                            {index + 1}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </> : <></>}
              </div>

              <div className="col-lg-10 col-sm-10" style={{ margin: "10px" }}>
                <Button variant="contained" size="large" color="success" onClick={Excel} style={{
                  width: "100%"
                }} >Download Data</Button>

              </div>
            </div>

          </Modal>
        </Card>
      </div>
    </>
  );
}
