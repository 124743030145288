// Start Date 19 April 2023
// TODO get virtual device child devices
// Get process data of thsi devices sum it and display it each one seconds
// Update the device list in Devicescontrol board
// TODO Update Boxes
// TODO Energy Charts

import React, { useState, useEffect, useRef, MouseEvent } from "react";
import DeviceStats from "./DeviceStats";
import VirtualDevicePowerChart from "./VirtualDevicePowerChart";
import VirtualDeviceEnergyChart from "./VirtualDeviceEnergyChart";
import DeviceSelector from "./DeviceSelector";
import UserService from "../../../services/user.service";
import moment from "moment-timezone";
import Boxes from "./Boxes";
import { Card, CardContent, Grid } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";

function sumEnergyByDate(data) {
  data = Object.values(data);
  console.log("xx", data);

  // if(data.length == 1) return {...data,diff:data.Accumulated_Energy_Solis}
  // Step 1: Create a map to group objects by date_time_A
  const groupedByDate = data.reduce((acc, obj) => {
    if (!acc[obj.date_time_A]) {
      acc[obj.date_time_A] = [];
    }
    acc[obj.date_time_A].push(obj);
    return acc;
  }, {});

  console.log("groupedByDate", groupedByDate);
  // Step 2: Collect all unique id222 values
  const allIds = [...new Set(data.map((obj) => obj.id222))];

  // Step 3: Get an array of sorted dates
  const sortedDates = Object.keys(groupedByDate).sort(
    (a, b) => new Date(a) - new Date(b)
  );

  // Step 4: Ensure each group has all id222 values, look for the most recent value for missing id222
  const ensureAllIdsPresent = (groupedByDate, allIds, sortedDates) => {
    for (let i = 1; i < sortedDates.length; i++) {
      const currentDate = sortedDates[i];
      const previousDate = sortedDates[i - 1];
      const currentGroup = groupedByDate[currentDate];
      const previousGroup = groupedByDate[previousDate];

      const existingIds = currentGroup.map((obj) => obj.id222);
      const missingIds = allIds.filter((id) => !existingIds.includes(id));

      missingIds.forEach((missingId) => {
        // Find the most recent value of the missing id222 from the previous group
        const previousObject = previousGroup.find(
          (obj) => obj.id222 === missingId
        );
        if (previousObject) {
          const newObject = {
            id222: missingId,
            time: currentGroup[0].time,
            date_time_A: currentDate,
            Accumulated_Energy_Solis: previousObject.Accumulated_Energy_Solis,
          };
          currentGroup.push(newObject);
        }
      });
    }
  };

  ensureAllIdsPresent(groupedByDate, allIds, sortedDates);

  // Step 2: Sum the Accumulated_Energy_Solis for each date
  const summedData = Object.keys(groupedByDate).map((date) => {
    const totalEnergy = groupedByDate[date].reduce(
      (sum, item) => sum + item.Accumulated_Energy_Solis,
      0
    );
    return { date_time_A: date, Accumulated_Energy_Solis: totalEnergy };
  });

  // Step 3: Calculate the difference from the previous day
  summedData.sort((a, b) => new Date(a.date_time_A) - new Date(b.date_time_A)); // Sort dates chronologically

  let previousTotal = 0;
  const result = summedData.map((entry, index) => {
    const difference = entry.Accumulated_Energy_Solis - previousTotal;
    previousTotal = entry.Accumulated_Energy_Solis;
    return {
      date_time_A: entry.date_time_A,
      Accumulated_Energy_Solis: entry.Accumulated_Energy_Solis,
      diff: index === 0 ? entry.Accumulated_Energy_Solis : difference, // No difference for the first day
    };
  });

  console.log("xxr", result);
  if (result.length === 1) return result;
  else return result.slice(1);
  // return result.slice(1);
}
function getChartData(powerDataFromDB, field) {
  let dNotNull = 0;
  return Object.values(powerDataFromDB).map((x) => {
    let t, d;
    t = x.date_time_A;
    d = x[field];
    if (d != null && d != 0 && d != -127) dNotNull = d;
    d = d === null ? dNotNull : d;
    d = d === -127 ? dNotNull : d;
    d = d === 0 ? dNotNull : d;
    return [t, d];
  });
}

export default function App(props) {
  // console.log(props);
  const userID = props.userID;
  const DeviceId = props.DeviceObject.id;
  const DeviceName = props.DeviceName;
  const [isPower, setIsPower] = useState(true);
  const [isEnergy, setIsEnergy] = useState(false);
  const [childDevices, setChildDevices] = useState([]);
  const [isControlDevices, setIsControlDevices] = useState(false);
  const [Parameter, setParameter] = React.useState("power");

  const [StaticValue1, setStaticValue1] = useState(0);
  const [StaticValue2, setStaticValue2] = useState(0);
  const [StaticValue3, setStaticValue3] = useState(0);
  const [StaticValue4, setStaticValue4] = useState(0);
  const [StaticValue5, setStaticValue5] = useState(0);
  const [StaticValue6, setStaticValue6] = useState(0);
  const [StaticValue7, setStaticValue7] = useState(0);
  const [StaticValue8, setStaticValue8] = useState(0);
  const [Updatef, setUpdatef] = useState(1);

  function Update() {
    console.log("Updated");
    setUpdatef(1);
    getchildDevices(DeviceId);
    // console.log(childDevices);
    getVirtualDeviceMoroccoData(DeviceId);
  }
  function getDailyBox(TimeFormat) {
    let year = new Date().getFullYear() - 2000;
    let month = new Date().getMonth() + 1;
    let day = new Date().getDate();
    let en_1;
    let en_4;
    let payload = {
      "deviceId": DeviceId,
      type: TimeFormat,
      year,
      month,
      day
      // "TimeFormat": "daily"
    };
    UserService.PostEnergyDataVir(payload)
      .then((res) => {
        let DataFromDB = res.data.data.deviceData;
        // console.table(TimeFormat, DataFromDB);
        // let en_4 = Object.values(DataFromDB).filter(x => x.energy_type == '4' && x.day_index == day).reduce((sum, current) => sum + current.value, 0).toFixed(2);
        // console.log("en_1", en_1);
        // console.log("en_4",en_4);
        if (TimeFormat == "day") {
          en_1 = Object.values(DataFromDB).filter(x => x.energy_type == '1' && x.day_index == day).reduce((sum, current) => sum + current.value, 0).toFixed(2);

          setStaticValue2(en_1);
        }
        if (TimeFormat == "month") {
          en_1 = Object.values(DataFromDB).filter(x => x.energy_type == '1' && x.month_index == month).reduce((sum, current) => sum + current.value, 0).toFixed(2);
          en_4 = Object.values(DataFromDB).filter(x => x.energy_type == '4' && x.month_index == month).reduce((sum, current) => sum + current.value, 0).toFixed(2);
          // console.log(en_1,en_4);
          // console.log(((en_1 / en_4) * 100).toFixed(2));
          setStaticValue7(((en_1 / en_4) * 100).toFixed(2));

          setStaticValue3(en_1);
        }

      })
      .catch((err) => {
      });
  }

  function getSolarPower() {
    let fields;
    fields = ["Zero_Total_Power", "Power_Solis_AC_T_A"];

    let endTime = moment().format("YYYY-MM-DD HH:mm:ss");
    let startTime = moment()
      .subtract(1, "days")
      .format("YYYY-MM-DD HH:mm:ss");

    UserService.getVIrtualDeviceMoroccoData({
      id: DeviceId,
      type: "Process2", // process_process , power , energy
      // format,
      startDate: startTime, //`2024-05-15 00:00:00`,
      endDate: endTime, //`2024-05-16 00:00:00`,
    })
      .then((res) => {
        let data = res.data.data.deviceData;
        data = Object.values(data)[0];
        console.log("GEt Data!!!!!!!!!", data);
        setStaticValue1(data.Power_Solis_AC_T_A/1000);
        setStaticValue5(data.Zero_Total_Power.toFixed(2));

        setStaticValue4(data.Accumulated_Energy_Solis.toFixed(2));
        setStaticValue6(data.Energy_Grid_SM_T_A.toFixed(2));
        
        setStaticValue8((data.Accumulated_Energy_Solis*  0.0006956).toFixed(2));

      })
      .catch((err) => {
      });
  }
  function getVirtualDeviceMoroccoData(deviceId) {
    getDailyBox("day");
    getDailyBox("month");
    getSolarPower();
  }

  function getchildDevices(deviceId) {
    UserService.GetTreeViewData(userID).then(
      (response) => {
        const result = response.data.data.profile[0]
          .filter((x) => x.parent_id === DeviceId)
          .map((x) => x.device_id);
        // console.log("Child Devices", result);
        setChildDevices(result);
      },
      (error) => { }
    );
  }

  useEffect(() => {
    getchildDevices(DeviceId);
    // console.log(childDevices);
    getVirtualDeviceMoroccoData(DeviceId);
  }, [DeviceId,Updatef]);

  return (
    <>
      <div className="grpah_table">
        <div className="col-lg-12 box_graph device_name">
          <div className="widget_categories right-widget top_heding ">
            <h4>
              {DeviceName}
              {/* <span style={{ background: `${isDeviceStatus}` }} /> */}
            </h4>
          </div>
        </div>
        <div className="col-lg-12 box_graph">
          <Boxes
            virtual={1}
            StaticTxtValue1="Solar Power"
            StaticValue1={StaticValue1}
            StaticUnit1="KW"
            StaticSize1={3}
            StaticTxtValue2="Daily Solar Energy"
            StaticValue2={StaticValue2}
            StaticUnit2="KWH"
            StaticSize2={3}
            StaticTxtValue3="Monthly Solar Energy"
            StaticValue3={StaticValue3}
            StaticUnit3="KWH"
            StaticSize3={3}
            StaticTxtValue4="Accumulated Solar Energy"
            StaticValue4={StaticValue4}
            StaticUnit4="KWH"
            StaticSize4={3}
            StaticTxtValue5="Load Power"
            StaticValue5={StaticValue5}
            StaticUnit5="KW"
            StaticSize5={3}
            StaticTxtValue6="Accumulated Load Energy"
            StaticValue6={StaticValue6}
            StaticUnit6="KWH"
            StaticSize6={3}

            StaticTxtValue7="RER"
            StaticValue7={StaticValue7}
            StaticUnit7="%"
            StaticSize7={3}

            StaticTxtValue8="CO2"
            StaticValue8={StaticValue8}
            StaticUnit8="TON"
            StaticSize8={3}

          ></Boxes>

        </div>
        <div className="col-lg-12 box_graph">
          <div className="col-lg-12 col-sm-12">
            <Card
              className="row"
              sx={{
                "border-radius": "1% ",

                boxShadow: 2,
                margin: "",
                ":hover": {
                  boxShadow: 5,
                },
                padding: "1%",
                marginTop: "10px",
                marginBottom: "5px",
              }}
            >
              <div className="col-lg-12 col-sm-12">
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Options</InputLabel>
                  <Select
                    style={{ width: "100%" }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={Parameter}
                    label="Format"
                    // onChange={handleChangeParameter}
                    onChange={(event) => {
                      setParameter(event.target.value);
                      switch (event.target.value) {
                        case "power":
                          setIsPower(1);
                          setIsEnergy(0);
                          setIsControlDevices(0);
                          break;
                        case "energy":
                          setIsPower(0);
                          setIsEnergy(1);
                          setIsControlDevices(0);
                          break;
                        case "devices":
                          setIsPower(0);
                          setIsEnergy(0);
                          setIsControlDevices(1);
                          break;

                        default:
                          break;
                      }
                    }}
                  >
                    <MenuItem value={"power"}>Power </MenuItem>
                    <MenuItem value={"energy"}>Energy </MenuItem>
                    <MenuItem value={"devices"}>Devices </MenuItem>
                  </Select>
                </FormControl>
              </div>
            </Card>
          </div>
          <div className="tags top_tag">
            {isPower ? (
              <VirtualDevicePowerChart
                DeviceId={DeviceId}
                userID={userID}
                param="power"
                type="line"
              />
            ) : // <h1>hi power </h1>

              null}
            {isEnergy ? (
              <VirtualDeviceEnergyChart
                DeviceId={DeviceId}
                userID={userID}
                param="energy"
                type="bar"
              />
            ) : // <h1>hi energy </h1>
              null}
            {isControlDevices ? (
              <DeviceSelector DeviceId={DeviceId} userID={userID}   Update ={Update}/>
            ) : null}
          </div>
        </div>
      </div>
      ;
    </>
  );
}
